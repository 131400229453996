import config from '../config'
import apiService from './api'

export default {
  name: 'propertiesApi',
  components: {
    apiService
  },

  getpropertyTypes() {
    let url = "https://api.dwellys.com/v1/listings/types"

    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getpropertyCategories() {
    let url = "https://api.dwellys.com/v1/listings/categories"

    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getpropertyRequests(query) {
    return apiService.get(config.REQUEST_URL, query)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getCollaboratorPropertyRequest(id) {
    let url = config.URL + 'user_collaborator/' + id + '/property_requests';
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addRep(repData) {
    let url = config.REQUEST_URL + '/reps';
    return apiService.post(url, repData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getpropertyListing(listingId) {
    let url = 'https://api.dwellys.com/v1/listings?listing_id=' + listingId
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
}