function getApiUrl() {
  let hn = window.location.hostname;
  if (hn === "localhost") {
    return "https://staging.dwellysops.app/v1/";
  } else if (hn == "testdoa.dwellys.com") {
    return "https://staging.dwellysops.app/v1/";
  } else if (hn == "dwellysops.app") {
    return "https://api.dwellysops.app/v1/";
  } else {
    return "https://staging.dwellysops.app/v1/";
  }
}

const API_URL = getApiUrl();

export default {
  HOST_NAME: window.location.hostname,
  URL: API_URL,
  LOGIN_URL: API_URL + "login",
  REGISTER_URL: API_URL + "register",
  FORGET_PASSWORD_URL: API_URL + "forgotpassword",
  USER_ROLES_URL: API_URL + "roles",
  USERS_URL: API_URL + "users",
  CLIENTS_URL: API_URL + "clients",
  INQUIRIES_URL: API_URL + "property_inquiries",
  REQUEST_URL: API_URL + "property_requests",
  BROKER_AREA_URL: API_URL + "brokers",
  BROKER_ASSIGN_URL: API_URL + "broker_assign/collaborators_property_requests",
  COLLABORATOR_URL: API_URL + "collaborator_files",
  TRIPS_URL: API_URL + "property_request_trips",
  MESSAGES_URL: API_URL + "messages",
  PAYMENTS_URL: API_URL + "trip_viewing_fee_payments",
  REVIEWS_URL: API_URL + "reviews",
  PROPERTY_SALES: API_URL + "property_sales",
  ENQUIRY_SOURCE_COUNT: API_URL + "inquiries_source/count",
  FOLLOW_UP_URL: API_URL + "client_follow_ups",
  PROPERTY_DOCS: API_URL + "property_request_document"
};
