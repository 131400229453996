<template>
  <div>
    <el-form :model="feeDetails" :rules="rules" ref="feeDetails" label-position="top">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Mode of Payment">
            <el-select v-model="feeDetails.mode_of_payment" style="width:100%;">
              <el-option
                v-for="pay in modeOfPayment"
                :label="pay"
                :value="pay"
                :key="pay"
              ></el-option>
            </el-select>
          </el-form-item>
          <div v-if="feeDetails.mode_of_payment == 'momo'">
            <el-form-item label="Select Momo Type">
              <el-radio v-model="feeDetails.momo_type" label="Mtn MoMo">Mtn MoMo</el-radio>
              <el-radio v-model="feeDetails.momo_type" label="AirtelTigo Cash"
                >AirtelTigo Cash</el-radio
              >
              <el-radio v-model="feeDetails.momo_type" label="Vodafone Cash"
                >Vodafone Cash</el-radio
              >
            </el-form-item>
          </div>
        </el-col>

        <div v-if="feeDetails.mode_of_payment == 'bank'">
          <el-col :span="24">
            <el-form-item label="Bank">
              <el-input v-model="feeDetails.bank" placeholder="bank name" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="Branch">
              <el-input v-model="feeDetails.branch" placeholder="branch of bank" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="Account Name">
              <el-input v-model="feeDetails.account_name" placeholder="account name" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="Account Number">
              <el-input v-model="feeDetails.account_number" placeholder="account number" />
            </el-form-item>
          </el-col>
        </div>

        <el-col :span="24">
          <el-form-item prop="momo_transaction_id">
            <el-input v-model="feeDetails.momo_transaction_id" placeholder="enter transaction id" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="amount">
            <el-input v-model="feeDetails.amount" placeholder="amount paid" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        class="full-width"
        type="primary"
        icon="el-icon-coins"
        :loading="btnLoading"
        @click="confirmFee"
        >Confirm Trip Fee Payments</el-button
      >
    </el-form>
  </div>
</template>

<script>
import paymentsApi from "@/api/payments";

export default {
  props: {
    tripId: String
  },
  data() {
    return {
      btnLoading: false,
      modeOfPayment: ["momo", "cash", "bank"],
      feeDetails: {
        property_request_trip_uuid: "",
        mode_of_payment: "",
        momo_type: "",
        bank: "",
        account_number: "",
        branch: "",
        account_name: "",
        momo_transaction_id: "",
        amount: ""
      },
      rules: {
        momo_transaction_id: [
          {
            required: true,
            message: "enter transaction id",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.feeDetails.property_request_trip_uuid = this.tripId;
  },
  methods: {
    confirmFee() {
      this.btnLoading = true;
      this.$refs["feeDetails"].validate(valid => {
        if (valid) {
          paymentsApi
            .addTripViewingFee(this.feeDetails)
            .then(() => {
              this.btnLoading = false;
              this.successNotification("Success", "Payments confirmation successfully");
              this.$emit("closeModal");
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    }
  }
};
</script>
