<template>
  <div>
    <div class="mb-2 flex-justify-between">
      <el-button icon="el-icon-s-promotion" plain type="primary" @click="showMessageModal = true">
        Send Message
      </el-button>

      <el-input placeholder="search" v-model="search" class="mt-4" style="width:50%"> </el-input>
    </div>
    <el-card>
      <el-table
        :data="
          tableData.filter(
            data => !search || data.message.toLowerCase().includes(search.toLowerCase())
          )
        "
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column label="Recipient" prop="recipient_name" />
        <el-table-column label="Email" prop="recipient_email" />
        <el-table-column label="Contact" prop="recipient_phone" />
        <el-table-column label="Channel" prop="channel" />
        <el-table-column label="Message" prop="message" />

        <el-table-column label="Sent" prop="created_at" />
        <el-table-column label="Action" width="100">
          <template>
            <el-button type="danger" size="mini" icon="el-icon-delete">
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <div style="text-align: center;margin-top: 10px; padding-bottom:10px;">
      <el-pagination
        background
        :current-page.sync="currentPage"
        :page-sizes="[20, 50, 100]"
        :page-size="20"
        layout="prev, pager, next, sizes, total"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- Assign user role modal -->
    <el-dialog
      :visible.sync="showMessageModal"
      width="40%"
      title="Message User"
      custom-class="mobile-modal"
    >
      <MessageUser v-if="showMessageModal" v-on:messaged="messageSent" />
    </el-dialog>
  </div>
</template>

<script>
import messagesApi from "@/api/messages";
import MessageUser from "@/components/MessageUser";

export default {
  name: "Messages",
  components: {
    MessageUser
  },
  data() {
    return {
      showMessageModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      tableData: [],
      tableLoading: false
    };
  },
  created() {
    this.getMessages();
  },
  methods: {
    getMessages() {
      this.tableLoading = true;
      messagesApi.getMessages().then(response => {
        this.loadTable(response.data);
      });
    },
    loadTable(data) {
      let self = this;
      let messages = data.map(function(message) {
        message.created_by = `${message.created_by.first_name} ${message.created_by.last_name}`;
        message.created_at = self.getDate(message.created_at);
        message.channel = message.message_channel.channel_name;
        message.message = message.message.message;
        message.recipient_name = `${message.client.first_name} ${message.client.last_name}`;
        message.recipient_email = message.client.email;
        message.recipient_phone = message.client.contact;
        return message;
      });

      this.tableData = messages;
      this.total = this.tableData.length;
      this.tableLoading = false;
    },
    messageSent() {
      this.showMessageModal = false;
      this.successNotification("Success", "Message sent successfully");
      this.getMessages();
    }
  }
};
</script>
