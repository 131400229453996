import apiService from './api'
import config from '../config'

export default {
  name: 'rolesApi',
  components: {
    apiService
  },
  getAllRoles() {
    return apiService.get(config.USER_ROLES_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getRolesUsers() {
    let url = config.USER_ROLES_URL + 'users'
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addRole(roleData) {
    let url = config.USER_ROLES_URL
    return apiService.post(url, roleData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  assignUserRole(roleUserDetails) {
    let url = config.URL + 'roles_users'
    return apiService.post(url, roleUserDetails)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  revokeUserRole(roleUserDetails) {
    let url = config.URL + 'revoke_user_role'
    return apiService.post(url, roleUserDetails)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  rolePermissions() {
    let url = config.URL + 'roles_with_permissions'
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getPermissions(query) {
    let url = config.URL + 'permissions'
    return apiService.get(url, query)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addPermissionToRole(rolePermission) {
    let url = config.URL + 'assign_role_permissions'
    return apiService.post(url, rolePermission)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  removePermissionFromRole(rolePermission) {
    let url = config.URL + 'revoke_permission_from_role'
    return apiService.post(url, rolePermission)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
}