import apiService from './api'
import config from '../config'

export default {
  name: 'tripsApi',
  components: {
    apiService
  },
  createTrip(trip) {
    let url = config.TRIPS_URL;
    return apiService.post(url, trip)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getTrips(query) {
    let url = config.TRIPS_URL;
    return apiService.get(url, query)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getTrip(id) {
    let url = config.TRIPS_URL + '/' + id
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  updateTripStatus(tripInfo) {
    let url = config.TRIPS_URL + '/' + tripInfo.uuid;
    return apiService.update(url, tripInfo)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  updateMangerTripStatus(tripInfo) {
    let url = config.TRIPS_URL + '/' + tripInfo.uuid + '/approval'
    return apiService.update(url, tripInfo)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  clientConfirmTrip(tripInfo) {
    let url = config.URL + 'confirmtrip'
    return apiService.post(url, tripInfo)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getClientTrip(id) {
    let url = config.URL + 'show_client_property_request_trips/' + id;
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  endTrip(tripInfo) {
    let url = config.URL + 'property_request_end_trips/'
    return apiService.post(url, tripInfo)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
}