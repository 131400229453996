<template>
  <div>
    <div class="mb-2 flex-justify-between">
      <h2>{{ title }}</h2>
      <!-- 
      <el-button icon="el-icon-plus" plain type="primary" @click="showAddEnquiryModal = true">
        Add New Enquiry
      </el-button> -->
    </div>

    <div>
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane name="request">
          <span slot="label"><i class="el-icon-video-pause"></i> {{ requestTab }}</span>
          <PropertyRequestLists type="request" v-on:loaded="requestLoaded" />
        </el-tab-pane>

        <el-tab-pane name="confirmed">
          <span slot="label"><i class="el-icon-circle-check"></i> {{ confirmedTab }}</span>
          <PropertyRequestLists type="confirmed" v-on:loaded="requestLoaded" />
        </el-tab-pane>
        <!-- 
        <el-tab-pane name="sales">
          <span slot="label"><i class="el-icon-coin"></i> {{ salesTab }}</span>
          <PropertyRequestLists type="sales" v-on:loaded="requestLoaded" />
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import PropertyRequestLists from "@/components/requests/PropertyRequestLists";

export default {
  name: "PropertyRequests",
  components: {
    PropertyRequestLists
  },
  data() {
    return {
      activeTab: "request",
      requestTab: "New",
      requestTotal: 0,
      confirmedTab: "Cleared for Viewing",
      confirmTotal: 0,
      salesTab: "In Sales",
      salesTotal: 0,
      title: "Property Requests"
    };
  },
  methods: {
    requestLoaded(args) {
      let type = args.type;
      let total = args.total;

      if (type == "request") {
        this.requestTab = "New (" + total + ")";
        this.requestTotal = total;
      } else if (type == "confirmed") {
        this.confirmedTab = "Cleared for Viewing (" + total + ")";
        this.confirmTotal = total;
      } else if (type == "sales") {
        this.salesTab = "In Sales (" + total + ")";
        this.salesTotal = total;
      }

      let requestsTotal = this.requestTotal + this.confirmTotal;
      this.title = "Property Requests (" + requestsTotal + ")";
    }
  }
};
</script>
