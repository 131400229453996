<template>
  <div>
    <div class="mb-2 mt-1">
      <el-input placeholder="search" v-model="search" class="mt-4 full-width">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>

    <el-card>
      <el-table
        :data="
          tableData.filter(
            data => !search || data.name.toLowerCase().includes(search.toLowerCase())
          )
        "
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column label="Name" prop="name">
          <template slot-scope="props">
            <span class="d-block">{{ props.row.name }}</span>
            <span class="d-block" v-if="props.row.role == 'collaborator'"><b>Individual</b></span>
          </template>
        </el-table-column>
        <el-table-column label="Email" prop="email" />
        <el-table-column label="Contact" prop="contact" />
        <el-table-column label="Last Login" prop="last_login_at">
          <template slot-scope="props">
            <span v-html="props.row.last_login_at"></span>
          </template>
        </el-table-column>
        <el-table-column label="Role" width="140">
          <template slot-scope="props">
            <el-tag size="mini" :type="props.row.role == 'no role' ? 'danger' : 'primary'">{{
              props.row.role
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template slot-scope="props">
            <el-dropdown class="ml-1">
              <!-- <img src="../assets/images/morep.svg" alt="more" style="margin-top:10px;"/> -->
              <i class="el-icon-more" style="font-weight:bold; font-size:25px;"></i>
              <el-dropdown-menu slot="dropdown">
                <span @click="viewDetails(props.row)" v-if="props.row.role == 'collaborator'">
                  <el-dropdown-item>
                    <i class="el-icon-user" style="margin-right: 10px"></i>
                    View Profile</el-dropdown-item
                  >
                </span>

                <el-dropdown-item>
                  <span @click="updateUser(props.row)">
                    <i class="el-icon-edit" style="margin-right: 10px"></i>
                    Edit Details
                  </span>
                </el-dropdown-item>
                <el-dropdown-item v-if="props.row.role == 'no role'">
                  <span
                    @click="assignUserRole(props.row.uuid)"
                    v-if="userCan('can_add_user_to_role')"
                  >
                    <i class="el-icon-s-custom" style="margin-right: 10px"></i>
                    Add role
                  </span>
                </el-dropdown-item>
                <el-dropdown-item v-else>
                  <span
                    @click="confirmRevoke(props.row.role, props.row.uuid)"
                    v-if="userCan('can_revoke_user_role')"
                  >
                    <i class="el-icon-refresh-left" style="margin-right: 10px"></i>
                    Revoke user role
                  </span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span
                    @click="editUserRole(props.row.uuid)"
                    v-if="userCan('can_add_user_to_role')"
                  >
                    <i class="el-icon-user-solid" style="margin-right: 10px"></i>
                    Edit user role
                  </span>
                </el-dropdown-item>

                <el-dropdown-item divided>
                  <span
                    v-if="props.row.role == 'supervising_broker'"
                    @click="assignArea(props.row.uuid)"
                  >
                    <i class="el-icon-house" style="margin-right: 10px"></i>
                    Assign an area
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- Edit user modal -->
    <el-dialog
      :visible.sync="showEditUserModal"
      width="30%"
      title="Edit User"
      custom-class="mobile-modal"
    >
      <EditUserDetails v-on:edited="userEdited" :user="selectedUser" v-if="showEditUserModal" />
    </el-dialog>

    <!-- Assign user role modal -->
    <el-dialog
      :visible.sync="showAssignRoleModal"
      width="30%"
      title="Assign Role"
      custom-class="mobile-modal"
    >
      <AssignUserRole
        v-on:assigned="roleAssigned"
        :userId="selectedId"
        v-if="showAssignRoleModal"
      />
    </el-dialog>

    <!-- Assign areas to users  -->
    <el-dialog
      :visible.sync="showAssignAreaModal"
      width="40%"
      title="Assign Area"
      :before-close="closeModal"
      custom-class="mobile-modal"
    >
      <AssignUserAreas
        v-on:closeModal="closeModal"
        :userId="selectedId"
        v-if="showAssignAreaModal"
      />
    </el-dialog>

    <el-dialog
      :visible.sync="showCollaboratorProfile"
      width="40%"
      :before-close="closeModal"
      title="Collaborator Profile"
      custom-class="mobile-modal"
    >
      <CollaboratorProfile v-if="showCollaboratorProfile" :user="selectedUser" />
    </el-dialog>

    <!-- Edit user role -->
    <el-dialog
      :visible.sync="showEditUserRoleModal"
      width="23%"
      title="Edit User Role"
      :before-close="closeModal"
      custom-class="mobile-modal"
    >
      <EditUserRole :userId="selectedId" v-if="showEditUserRoleModal" />
    </el-dialog>

    <!-- Assign collaborators  -->
    <!-- <el-dialog :visible.sync="assignColaboratorModal" width="30%" title="Assign Collaborator">
      <AssignCollaborators
        v-on:closeModal="colAsssigned"
        :userId="selectedId"
        v-if="assignColaboratorModal"
      />
    </el-dialog> -->
  </div>
</template>

<script>
import usersApi from "@/api/users";
import rolesApi from "@/api/roles";
import EditUserDetails from "@/components/users/EditUserDetails";
import AssignUserRole from "@/components/users/AssignUserRole";
import AssignUserAreas from "@/components/users/AssignUserAreas";
import EditUserRole from "@/components/users/EditUserRole";
import CollaboratorProfile from "@/components/collaborators/CollaboratorProfile";

export default {
  name: "Userlist",
  props: {
    type: String
  },
  components: {
    EditUserDetails,
    AssignUserRole,
    AssignUserAreas,
    EditUserRole,
    CollaboratorProfile
  },
  data() {
    return {
      showEditUserRoleModal: false,
      showCollaboratorProfile: false,
      assignColaboratorModal: false,
      showAssignRoleModal: false,
      showEditUserModal: false,
      showAssignAreaModal: false,
      selectedUser: null,
      selectedId: null,
      search: "",
      tableData: [],
      tableLoading: false,
      revokeRoleDetails: {
        user_uuid: "",
        role_id: ""
      }
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.tableLoading = true;
      usersApi
        .getUsers()
        .then(response => {
          this.loadTable(response.data);
        })
        .catch(error => this.errorMessage(error.message));
    },
    loadTable(data) {
      let self = this;
      let users = data.map(function(user) {
        user.role = user.role.length === 0 ? "no role" : user.role[0].role;
        user.name = user.first_name + " " + user.last_name;
        user.last_login_at = self.getTodaysDateAndTime(user.last_login_at);
        return user;
      });

      if (this.type == "others") {
        this.tableData = users.filter(user => user.role == "no role");
        this.$emit("loaded", { type: this.type, total: this.tableData.length });
      } else if (this.type == "system") {
        this.tableData = users.filter(
          user =>
            user.role !== "supervising_broker" &&
            user.role !== "no role" &&
            user.role !== "collaborator"
        );
        this.$emit("loaded", { type: this.type, total: this.tableData.length });
      } else if (this.type == "collaborator") {
        this.tableData = users.filter(user => user.role == "collaborator");
        this.$emit("loaded", { type: this.type, total: this.tableData.length });
      } else if (this.type == "broker") {
        this.tableData = users.filter(user => user.role == "supervising_broker");
        this.$emit("loaded", { type: this.type, total: this.tableData.length });
      }
      this.tableLoading = false;
    },
    viewDetails(user) {
      this.selectedUser = user;
      if (this.userCan("can_fetch_all_collaborators_files")) {
        this.showCollaboratorProfile = true;
      } else {
        this.infoMessage("You dont have permission to perform action.");
      }
    },
    assignUserRole(userId) {
      this.selectedId = userId;
      this.showAssignRoleModal = true;
    },
    updateUser(user) {
      this.selectedUser = user;
      this.showEditUserModal = true;
    },
    editUserRole(uuid) {
      this.selectedId = uuid;
      this.showEditUserRoleModal = true;
    },
    assignArea(uuid) {
      this.selectedId = uuid;
      this.showAssignAreaModal = true;
    },
    assignCollaborator(id) {
      this.selectedId = id;
      this.assignColaboratorModal = true;
    },
    userEdited() {
      this.showEditUserModal = false;
      this.getUsers();
    },
    roleAssigned() {
      this.showAssignRoleModal = false;
      this.getUsers();
    },
    closeModal(done) {
      done();
      this.getUsers();
    },
    confirmRevoke(role, userId) {
      rolesApi
        .getAllRoles()
        .then(response => {
          let newrole = response.data.filter(r => r.role == role);
          this.revokeRoleDetails.role_id = newrole[0].id;
          this.revokeRoleDetails.user_uuid = userId;
          this.confirmUserRevoke(role);
        })
        .catch(error => this.errorMessage(error.message));
    },
    confirmUserRevoke(role) {
      this.$confirm(`This will permanently remove role as a ${role}`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.revokeRole();
        })
        .catch(() => {
          this.infoMessage("Cancelled");
        });
    },
    revokeRole() {
      rolesApi
        .revokeUserRole(this.revokeRoleDetails)
        .then(() => {
          this.successNotification("Success", "Role revoked successfully");
          this.getUsers();
        })
        .catch(error => this.errorMessage(error.message));
    }
  }
};
</script>
