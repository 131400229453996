<template>
  <div>
    <p>show reviews table</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: []
    };
  }
};
</script>
