<template>
  <div>
    <el-card>
      <el-table
        :data="tableData"
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column label="Doc" width="220px">
          <template slot-scope="props">
            <el-image style="width: 120px" :src="props.row.file_name" fit="contain">
              <div slot="error" class="image-slot">
                <img src="../../assets/images/pdf.png" alt="pdf" width="100px" />
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="File name">
          <template slot-scope="props">
            <span class="d-block">{{ props.row.name }}</span>
            <span class="d-block">
              <a
                download="custom-filename.jpg"
                :href="props.row.file_name"
                title="ImageName"
                target="blank"
              >
                <el-button size="mini" type="success">Download</el-button>
              </a>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Description" prop="description" />

        <el-table-column label="Uploaded by">
          <template slot-scope="props">
            <span class="d-block">
              <i class="el-icon-user"></i>
              <b> {{ props.row.createdBy }}</b>
            </span>
            <span class="d-block">
              <i class="el-icon-phone"></i>
              {{ props.row.uploaded_by.contact }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="Date">
          <template slot-scope="props">
            <span style="font-size:12px" class="d-block">Created at</span>
            <span class="d-block">
              {{ getDate(props.row.created_at) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import config from "../../config";
import propertySalesApi from "@/api/property_sales";

export default {
  name: "PropertyRequestDocuments",
  props: {
    property_requestId: String
  },
  data() {
    return {
      tableData: [],
      tableLoading: false
    };
  },
  created() {
    this.getdocs();
  },
  methods: {
    getdocs() {
      this.tableLoading = true;
      propertySalesApi
        .getPropertyDocs(this.property_requestId)
        .then(response => {
          this.loadTable(response);
        })
        .catch(error => this.errorMessage(error.message));
    },
    loadTable(response) {
      let docs = response.data.map(function(doc) {
        doc.createdBy = doc.uploaded_by.first_name + " " + doc.uploaded_by.last_name;
        doc.file_name =
          config.HOST_NAME == "localhost"
            ? "https://staging.dwellysops.app/storage/" + doc.file_name
            : "https://api.dwellysops.app/storage/" + doc.file_name;
        return doc;
      });
      this.tableData = docs;
      this.tableLoading = false;
    }
  }
};
</script>
