<template>
  <div>
    <div class="mb-2">
      <el-input placeholder="search" v-model="search" class="mt-4 full-width">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>

    <el-card>
      <el-table
        :data="tableData"
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column label="Momo Type" prop="momo_type" />
        <el-table-column label="Amount" prop="amount" />
        <el-table-column label="Transaction ID" prop="momo_transaction_id" />
        <el-table-column label="Info">
          <template slot-scope="props">
            <span class="d-block mt-1">
              <span style="font-size:11px">Confirmed By</span> <br />
              <i class="el-icon-info"></i>
              <b> {{ props.row.created_by }}</b>
            </span>
            <span class="d-block mt-1">
              <span style="font-size:11px">Payment Date</span> <br />
              <i class="el-icon-info"></i>
              <b> {{ props.row.paid_date }}</b>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <div style="text-align: center;margin-top: 10px; padding-bottom:10px;">
      <el-pagination
        background
        :current-page.sync="currentPage"
        :page-sizes="[20, 50, 100]"
        :page-size="20"
        layout="prev, pager, next, sizes, total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import paymentsApi from "@/api/payments";

export default {
  name: "TripPayments",
  data() {
    return {
      selectedTripId: "",
      showAddViewingFeeModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      tableData: [],
      tableLoading: false
    };
  },
  computed: {
    ...mapGetters({
      role: "getRole",
      user: "getUser"
    })
  },
  created() {
    this.getPayments();
  },
  methods: {
    getPayments() {
      this.tableLoading = true;
      paymentsApi
        .getTripViewingFees()
        .then(response => {
          this.loadTable(response.data);
          this.tableData = response.data;
          this.tableLoading = false;
        })
        .catch(error => {
          (this.tableLoading = false), this.errorMessage(error.message);
        });
    },
    loadTable(data) {
      let self = this;
      let payments = data.map(function(payment) {
        payment.created_by = `${payment.created_by.first_name} ${payment.created_by.last_name}`;
        payment.paid_date = self.getDate(payment.created_at);
        payment.amount = "GH₵ " + payment.amount;
        return payment;
      });

      this.tableData = payments;
      this.total = this.tableData.length;
    }
  }
};
</script>
