<template>
  <div>
    <div>
      <h1>{{ getGreetings() }} {{ user.first_name }} !!</h1>
    </div>
    <br />

    <br />
    <div v-loading="dashLoading">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="8" :lg="8" v-if="userCan('can_fetch_all_property_requests')">
          <DashboardCard
            title="Property Requests"
            icon="el-icon-office-building"
            boardClass="dashboard_card2"
            color="primary"
            :totalNumber="requestTotal"
          />
        </el-col>

        <el-col :xs="24" :sm="24" :md="8" :lg="8" v-if="userCan('can_fetch_all_property_trips')">
          <DashboardCard
            title="Trips"
            icon="el-icon-truck"
            boardClass="dashboard_card"
            color="info"
            :totalNumber="tripsTotal"
          />
        </el-col>

        <el-col
          :xs="24"
          :sm="24"
          :md="8"
          :lg="8"
          v-if="userCan('can_fetch_all_property_requests_on_sales')"
        >
          <DashboardCard
            title="Sales"
            icon="el-icon-coin"
            boardClass="dashboard_card4"
            color="warning"
            :totalNumber="salesTotal"
          />
        </el-col>
      </el-row>

      <div class="mt-2" v-if="userCan('can_fetch_inquiries')">
        <h2>Track Inquries with thier source</h2>
        <el-row class="mt-1" :gutter="5">
          <el-col :xs="24" :sm="24" :md="5" :lg="5">
            <el-select
              v-model="enquirySort.source"
              placeholder="Select source"
              @change="selectChange"
            >
              <el-option v-for="source in sources" :key="source" :label="source" :value="source">
              </el-option>
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="18" :lg="18">
            <el-date-picker
              v-model="datePeriod"
              format="yyyy/MM/dd"
              value-format="yyyy/MM/dd"
              type="daterange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              @change="dateChange"
              style="width:60%"
            >
            </el-date-picker>
          </el-col>
        </el-row>

        <el-row class="mt-2" v-loading="enquiryLoading">
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <DashboardCard
              :title="enquiriesTitle"
              icon="el-icon-phone-outline"
              boardClass="dashboard_card3"
              color="success"
              :totalNumber="enquiryTotal"
            />
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- <el-row :gutter="20" class="mt-2">
      <h2 class="ml-2 mb-2">Activity Logs</h2>
      <el-col :xs="24" :sm="24" :md="10" :lg="10">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          element-loading-text="Loading..."
          element-loading-spinner="el-icon-loading"
          style="width: 100%"
        >
          <el-table-column label="Log Name" prop="log_name" />
          <el-table-column label="Description" prop="description" />
        </el-table>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import is from "is_js";
import dashboardApi from "@/api/dashboard";
import inquiriesApi from "@/api/inquiries";
import DashboardCard from "@/components/DashboardCard";

export default {
  components: {
    DashboardCard
  },
  data() {
    return {
      enquiryLoading: false,
      datePeriod: "",
      enquiriesTitle: "Total Enquries",
      sources: ["call", "website", "online", "walk-in", "facebook", "google", "linkedin"],
      tableData: [],
      tableLoading: false,
      dashLoading: true,
      tripsTotal: 0,
      enquiryTotal: 0,
      requestTotal: 0,
      salesTotal: 0,
      enquirySort: {
        source: "",
        from: "",
        to: ""
      }
    };
  },
  created() {
    this.getDashboardData();
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    })
  },
  methods: {
    getDashboardData() {
      this.dashLoading = true;
      if (this.user.role[0].role == "collaborator") {
        dashboardApi
          .getCollaboratorDashboard(this.user.uuid)
          .then(response => {
            this.requestTotal = response.data.total_requests;
            this.tripsTotal = response.data.total_trips;
          })
          .catch(errors => {
            this.errorMessage(errors.message);
          });
      } else {
        dashboardApi
          .getDashboardTotal()
          .then(response => {
            this.dashLoading = false;
            this.requestTotal = response.data.total_property_requests;
            this.enquiryTotal = response.data.total_property_inquiries;
            this.tripsTotal = response.data.total_property_trips;
            this.salesTotal = response.data.total_property_requests_in_sale;
          })
          .catch(error => {
            this.dashLoading = false;
            this.errorMessage(error.message);
          });
      }
    },
    dateChange(date) {
      this.enquirySort.from = date[0];
      this.enquirySort.to = date[1];
      this.getEnquiry();
    },
    selectChange(v) {
      this.enquirySort.source = v;
      if (is.array(this.datePeriod)) this.getEnquiry();
      else return false;
    },
    getEnquiry() {
      this.enquiryLoading = true;
      inquiriesApi
        .getEnquirySourceCount(this.enquirySort)
        .then(response => {
          console.log(response);
          this.enquiriesTitle = "Total Enquiries (" + this.enquirySort.source + ")";
          this.enquiryTotal = response.data;
          this.enquiryLoading = false;
        })
        .catch(error => {
          this.errorMessage(error.message);
          this.enquiryLoading = false;
        });
    }
    // getActivityLog() {
    //   dashboardApi
    //     .getActivityLog()
    //     .then(response => {
    //       this.tableData = response.data;
    //       console.log(response);
    //     })
    //     .catch(() => {});
    // }
  }
};
</script>
