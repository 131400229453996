<template>
  <div>
    <div class="mb-2">
      <el-button icon="el-icon-plus" plain type="primary" @click="showAddRoleModal = true">
        Add a Role
      </el-button>
    </div>

    <el-card>
      <el-table
        :data="tableData"
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column label="Name" prop="name" />
        <el-table-column label="Permissions">
          <template slot-scope="props">
            <el-tag
              type="info"
              v-for="perm in props.row.rolePermission"
              :key="perm.id"
              style="margin: 5px;"
            >
              {{ perm.name }}
            </el-tag>

            <span>
              <el-button type="text" size="mini" icon="el-icon-view"
                >View All Permissions</el-button
              >
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Actions">
          <template slot-scope="props">
            <el-button
              v-if="props.row.name !== 'admin' && props.row.name !== 'general_manager'"
              type="success"
              icon="el-icon-plus"
              @click="showAddPermission(props.row.name, props.row.id, props.row.permissions)"
            >
              Add Permission
            </el-button>

            <span v-else>
              Has all permissions
            </span>
            <!-- <el-button type="danger" icon="el-icon-cancel">
              Revoke
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- add a role -->
    <el-dialog
      :visible.sync="showAddRoleModal"
      width="30%"
      title="Add Role"
      custom-class="mobile-modal"
    >
      <el-form :model="roleDetails" :rules="rules" ref="roleDetails">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item prop="name">
              <el-input type="text" placeholder="name of role" v-model="roleDetails.name" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item>
              <el-input
                type="textarea"
                placeholder="Description of role"
                v-model="roleDetails.description"
                maxlength="100"
                show-word-limit
              />
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-button
          class="full-width"
          type="primary"
          icon="el-icon-plus"
          :loading="btnLoading"
          @click="addRole"
          >Add Role</el-button
        >
      </el-form>
    </el-dialog>

    <el-dialog
      :visible.sync="showAddPermissionModal"
      width="30%"
      :before-close="closeModal"
      :title="title"
      custom-class="mobile-modal"
    >
      <div>
        <el-select
          v-model="permissionRole.permissionId"
          filterable
          placeholder="search permission"
          style="width: 100%;"
        >
          <el-option
            v-for="item in newPermission"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <br /><br />
        <el-button
          type="primary"
          @click="addPermission"
          style="width: 100%;"
          :loading="btnPermLoading"
        >
          Add Permission
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import rolesApi from "@/api/roles";
import is from "is_js";

export default {
  name: "Roles",
  data() {
    return {
      title: "",
      showAddPermissionModal: false,
      newPermission: [],
      permissionsData: [],
      btnLoading: false,
      btnPermLoading: false,
      showAddRoleModal: false,
      search: "",
      tableData: [],
      tableLoading: false,
      roles: ["Front Desk", "Collaborator", "Broker", "Manager"],
      roleDetails: {
        name: ""
      },
      permissionRole: {
        roleId: "",
        permissionId: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "role name is required",
            trigger: "blur"
          }
        ]
      },
      query: {
        page: 1,
        limit: 58
      }
    };
  },
  created() {
    this.getRoles();
    // this.getPermissions();
  },
  methods: {
    getRoles() {
      let self = this;
      this.tableLoading = true;
      rolesApi
        .rolePermissions()
        .then(response => {
          self.permissionsData = response.data[0].permissions;
          let p = response.data.map(function(perm) {
            perm.rolePermission = perm.permissions.slice(0, 5);
            return perm;
          });
          this.tableLoading = false;
          this.tableData = p;
        })
        .catch(error => this.errorMessage(error.message));
    },
    getPermissions() {
      rolesApi
        .getPermissions(this.query)
        .then(response => {
          this.permissionsData = response.data;
        })
        .catch(error => this.errorMessage(error.message));
    },
    addRole() {
      this.btnLoading = true;
      this.$refs["roleDetails"].validate(valid => {
        if (valid) {
          rolesApi
            .addRole(this.roleDetails)
            .then(() => {
              this.btnLoading = false;
              this.successNotification("Success", "Role added successfully");
              this.showAddRoleModal = false;
              this.getRoles();
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    },
    showAddPermission(name, roleId, perm) {
      this.title = `Add Permission to ${name}`;
      this.permissionRole.roleId = roleId;
      console.log(perm);
      console.log(this.permissionsData);
      let userPerm = [];
      let newPerm = [];
      perm.forEach(function(p) {
        userPerm.push(p.name);
      });

      this.permissionsData.forEach(function(p) {
        if (is.inArray(p.name, userPerm) === false) {
          newPerm.push(p);
        }
      });
      this.newPermission = [];
      this.newPermission = newPerm;
      this.showAddPermissionModal = true;
    },
    addPermission() {
      this.btnPermLoading = true;
      rolesApi
        .addPermissionToRole(this.permissionRole)
        .then(() => {
          this.successNotification("Permission added successfully");
        })
        .catch(error => this.errorMessage(error.message));
    },
    closeModal(done) {
      this.newPermission = [];
      done();
    }
  }
};
</script>
