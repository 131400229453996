<template>
  <div>
    <el-card :class="boardClass">
      <div class="flex-justify-between">
        <span style="padding-top: 10px;"
          ><h2>{{ title }}</h2></span
        >
        <el-button :type="color" circle :icon="icon" size="large" />
      </div>
      <div>
        <span class="dashboard_number">
          {{ totalNumber }}
        </span>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    boardClass: String,
    color: String,
    totalNumber: Number
  }
};
</script>
