<template>
  <div>
    <el-card>
      <el-table
        :data="tableData"
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column label="Comment" prop="comment" />
        <el-table-column label="Action">
          <template slot-scope="props">
            <el-tag size="mini">{{ props.row.action_purpose }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Date">
          <template slot-scope="props">
            <span style="font-size:12px">Action date</span>
            <span class="d-block">
              {{ getDate(props.row.action_datetime) }}
            </span>
            <span style="font-size:12px" class="d-block">Created at</span>
            <span class="d-block">
              {{ getDate(props.row.created_at) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Added by">
          <template slot-scope="props">
            <span class="d-block">
              <i class="el-icon-user"></i>
              <b> {{ props.row.createdBy }}</b>
            </span>
            <span class="d-block">
              <i class="el-icon-phone"></i>
              {{ props.row.created_by.contact }}
            </span>
          </template>
        </el-table-column>

        <el-table-column>
          <template slot-scope="props">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="showEdit(props.row)"
            />

            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="confirmDelete(props.row.uuid)"
            >
              Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <div style="text-align: center;margin-top: 10px; padding-bottom:10px;">
      <el-pagination
        background
        :current-page.sync="currentPage"
        :page-sizes="[15, 30, 60]"
        :page-size="15"
        layout="prev, pager, next, sizes, total"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- edit follow-up dialog -->
    <el-dialog
      :visible.sync="showEditModal"
      width="30%"
      custom-class="mobile-modal"
      title="Edit Follow up"
      append-to-body
    >
      <el-form :model="followup" :rules="rules" ref="followup">
        <el-form-item prop="comment" label="Follow up message">
          <el-input type="textarea" placeholder="Enter message" v-model="followup.comment" />
        </el-form-item>
        <el-form-item prop="action_purpose" label="Follow up action">
          <el-select v-model="followup.action_purpose" class="full-width">
            <el-option label="Viewing" value="viewing"></el-option>
            <el-option label="Negotiation" value="negotiation"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Action Date">
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            editable
            type="datetime"
            placeholder="2021-03-13"
            style="width: 100%;"
            v-model="followup.action_datetime"
          >
          </el-date-picker>
        </el-form-item>
        <el-button
          class="full-width"
          type="primary"
          icon="el-icon-edit"
          :loading="btnLoading"
          @click="editClientFollowup"
          >Edit Message</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import clientsApi from "@/api/clients";

export default {
  name: "clientsFollowUp",
  props: {
    clientId: String
  },
  data() {
    return {
      btnLoading: false,
      showEditModal: false,
      currentPage: 1,
      total: 0,
      tableLoading: false,
      tableData: [],
      client: {
        client_uuid: ""
      },
      followup: {
        comment: "",
        follow_up_uuid: "",
        action_purpose: "",
        action_datetime: ""
      },
      rules: {
        comment: [
          {
            required: true,
            message: "message is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.client.client_uuid = this.clientId;
    this.getClientFollowUp();
  },
  methods: {
    getClientFollowUp() {
      this.tableLoading = true;
      clientsApi
        .getClientsFollowUp(this.client)
        .then(response => {
          console.log(response);
          this.loadTable(response);
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    loadTable(response) {
      let table = response.data.map(function(followup) {
        followup.createdBy = followup.created_by.first_name + " " + followup.created_by.last_name;
        return followup;
      });

      this.tableData = table;
      this.total = response.pagination.total;
      this.tableLoading = false;
    },
    showEdit(followup) {
      this.followup.comment = followup.comment;
      this.followup.follow_up_uuid = followup.uuid;
      this.showEditModal = true;
    },
    editClientFollowup() {
      this.btnLoading = true;
      this.$refs["followup"].validate(valid => {
        if (valid) {
          clientsApi
            .updateClientsFollowUp(this.followup)
            .then(() => {
              this.btnLoading = false;
              this.showEditModal = false;
              this.successMessage("Follow up edited successfully");
              this.getClientFollowUp();
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    },
    confirmDelete(id) {
      console.log(id);
      this.$confirm("This will permanently delete follow up message", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.deleteFollowup(id);
        })
        .catch(() => {
          this.infoMessage("Cancelled");
        });
    },
    deleteFollowup(id) {
      this.tableLoading = true;
      clientsApi
        .deleteClientsFollowUp(id)
        .then(() => {
          this.successMessage("Follow up deleted successfully");
          this.getClientFollowUp();
        })
        .catch(error => {
          this.tableLoading = false;
          this.errorMessage(error.message);
        });
    }
  }
};
</script>
