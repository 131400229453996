<template>
  <div>
    <div class="mb-2 flex-justify-between">
      <h2>{{ title }}</h2>

      <el-button icon="el-icon-plus" plain type="primary" @click="showAddEnquiryModal = true">
        Add New Enquiry
      </el-button>
    </div>

    <div>
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane name="pending">
          <span slot="label"><i class="el-icon-video-pause"></i> {{ pendingTab }}</span>
          <EnquiryList type="pending" v-on:loaded="enquiryLoaded" />
        </el-tab-pane>

        <el-tab-pane name="confirmed">
          <span slot="label"><i class="el-icon-circle-check"></i> {{ confirmedTab }}</span>
          <EnquiryList type="confirmed" v-on:loaded="enquiryLoaded" />
        </el-tab-pane>

        <el-tab-pane name="cancelled">
          <span slot="label"><i class="el-icon-circle-close"></i> {{ cancelledTab }}</span>
          <EnquiryList type="cancelled" v-on:loaded="enquiryLoaded" />
        </el-tab-pane>

        <el-tab-pane name="notAvailable">
          <span slot="label"> <i class="el-icon-question"></i> {{ notAvailableTab }}</span>
          <EnquiryList type="property_not_available" v-on:loaded="enquiryLoaded" />
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- add new enquiry dialog -->
    <el-dialog :visible.sync="showAddEnquiryModal" width="50%" custom-class="mobile-modal">
      <template slot="title">
        <h2>Add Property Enquiry</h2>
        <p>All fields should be filled for accurate property search.</p>
      </template>
      <AddPropertyEnquiry v-on:addedEnquiry="enquiryAdded" clientId="noId" />
    </el-dialog>
  </div>
</template>

<script>
import EnquiryList from "@/components/enquiries/EnquiryList";
import AddPropertyEnquiry from "@/components/enquiries/AddPropertyEnquiry";

export default {
  name: "Enquiries",
  components: {
    AddPropertyEnquiry,
    EnquiryList
  },
  data() {
    return {
      showAddEnquiryModal: false,
      activeTab: "pending",
      pendingTab: "New",
      pendingTotal: 0,
      confirmedTab: "Confirmed",
      confirmTotal: 0,
      cancelledTab: "Cancelled",
      cancelledTotal: 0,
      notAvailableTab: "Not Available",
      notAvailableTotal: 0,
      title: "Enquiries"
    };
  },

  methods: {
    enquiryLoaded(args) {
      let type = args.type;
      let total = args.total;

      if (type == "pending") {
        this.pendingTab = "New (" + total + ")";
        this.pendingTotal = total;
      } else if (type == "confirmed") {
        this.confirmedTab = "Confirmed (" + total + ")";
        this.confirmTotal = total;
      } else if (type == "cancelled") {
        this.cancelledTab = "Cancelled (" + total + ")";
        this.cancelledTotal = total;
      } else if (type == "property_not_available") {
        this.notAvailableTab = "Not Available (" + total + ")";
        this.notAvailableTotal = total;
      }

      let enquiriesTotal =
        this.pendingTotal + this.confirmTotal + this.cancelledTotal + this.notAvailableTotal;
      this.title = "Enquiries (" + enquiriesTotal + ")";
    },
    enquiryAdded() {
      this.showAddEnquiryModal = false;
      this.$router.go();
    }
  }
};
</script>
