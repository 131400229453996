<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="12" v-for="(property, index) in listing" :key="index">
        <el-card :body-style="{ padding: '0px' }">
          <!-- <img :src="property.thumbnail" class="image" /> -->
          <div style="padding: 14px;">
            <h3>{{ getSlug(property.listing) }}</h3>
            <div class="bottom clearfix">
              <span class="d-block"
                ><a :href="property.listing" target="_blank">View property</a></span
              >
            </div>
          </div>

          <div style="padding: 10px;">
            <el-button type="primary" size="mini" @click="selectedProperty(property.listing)"
              >Choose as selected</el-button
            >
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import propertySales from "@/api/property_sales";

export default {
  name: "Selected Property",
  props: {
    listing: Array,
    requestId: String
  },
  data() {
    return {
      sales: {
        property_request_uuid: "",
        selected_property_listings: ""
      }
    };
  },
  created() {
    this.sales.property_request_uuid = this.requestId;
  },
  methods: {
    selectedProperty(propertyUrl) {
      this.sales.selected_property_listings = propertyUrl;
      propertySales
        .addPropertySales(this.sales)
        .then(() => {
          this.successMessage("A new deal has been created for selected property");
          this.$router.push("/property-sales");
        })
        .catch(error => this.errorMessage(error.message));
    },
    getSlug(url) {
      var slug = url.replace("https://dwellys.com/properties/", "");
      return slug;
    }
  }
};
</script>
