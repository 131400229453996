// store.js
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import doaauth from './modules/auth';
import doanotifications from './modules/notifications';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    doaauth,
    doanotifications
  },
  plugins: [
    createPersistedState(),
  ],
});
