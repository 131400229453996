<template>
  <div>
    <div class="mb-2 mt-1">
      <el-input placeholder="search" v-model="search" class="mt-4 full-width">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>

    <el-card>
      <el-table
        :data="
          tableData.filter(
            data => !search || data.name.toLowerCase().includes(search.toLowerCase())
          )
        "
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column label="Name" width="160">
          <template slot-scope="props">
            <span class="d-block">
              <i class="el-icon-user"></i>
              <b> {{ props.row.name }}</b>
            </span>
            <span class="d-block">
              <el-tag type="primary" size="mini">{{ props.row.gender }}</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Contact" width="200">
          <template slot-scope="props">
            <span class="d-block">
              <i class="el-icon-eleme"></i>
              <b> {{ props.row.email }}</b>
            </span>
            <span class="d-block">
              <i class="el-icon-phone"></i>
              {{ props.row.contact }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Profession" prop="profession" align="center" />
        <el-table-column label="Marital Status" width="150">
          <template slot-scope="props">
            <span class="d-block">
              <el-tag type="primary" size="mini">{{ props.row.marital_status }}</el-tag>
            </span>
            <span class="d-block">
              Age range: <b>{{ props.row.age_range }}</b>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Info">
          <template slot-scope="props">
            <span class="d-block clickable" @click="viewFollowUps(props.row.uuid, props.row.name)">
              <el-tag type="primary" size="mini">View follow ups</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="props">
            <el-tooltip class="item" effect="dark" content="Create an enquiry" placement="top">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-phone"
                @click="showEnquiryModal(props.row.uuid, props.row.name)"
              />
            </el-tooltip>
            <el-button
              size="mini"
              type="success"
              icon="el-icon-phone-outline"
              @click="followUp(props.row.uuid)"
            >
              Follow up</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <div style="text-align: center;margin-top: 10px; padding-bottom:10px;">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[15, 30, 60]"
        :page-size="15"
        layout="prev, pager, next, sizes, total"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- add new enquiry dialog -->
    <el-dialog :visible.sync="showAddEnquiryModal" width="50%" custom-class="mobile-modal">
      <template slot="title">
        <h2>{{ selectedClientName }}</h2>
        <p>All fields should be filled for accurate property search.</p>
      </template>
      <AddPropertyEnquiry
        v-on:addedEnquiry="closeDialog"
        :clientId="selectedClientId"
        v-if="showAddEnquiryModal"
      />
    </el-dialog>

    <!-- add follow up  -->
    <el-dialog
      :visible.sync="showFollowUpModal"
      width="30%"
      custom-class="mobile-modal"
      title="Follow up"
    >
      <el-form :model="followup" :rules="rules" ref="followup">
        <el-form-item prop="comment" label="Follow up message">
          <el-input type="textarea" placeholder="Enter message" v-model="followup.comment" />
        </el-form-item>
        <el-form-item prop="action_purpose" label="Follow up action">
          <el-select v-model="followup.action_purpose" class="full-width">
            <el-option label="Viewing" value="viewing"></el-option>
            <el-option label="Negotiation" value="negotiation"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Action Date">
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            editable
            type="datetime"
            placeholder="2021-03-13"
            style="width: 100%;"
            v-model="followup.action_datetime"
          >
          </el-date-picker>
        </el-form-item>
        <el-button
          class="full-width"
          type="primary"
          icon="el-icon-phone-outline"
          :loading="btnLoading"
          @click="addClientFollowup"
          >Add Message</el-button
        >
      </el-form>
    </el-dialog>

    <!-- show follow table -->
    <el-dialog :visible.sync="showFollowupTable" width="60%" custom-class="mobile-modal">
      <template slot="title">
        <h2>{{ selectedClientName }}</h2>
      </template>
      <div>
        <ClientsFollowUp v-if="showFollowupTable" :clientId="selectedClientId" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import clientsApi from "@/api/clients";
import AddPropertyEnquiry from "@/components/enquiries/AddPropertyEnquiry";
import ClientsFollowUp from "@/components/clients/ClientsFollowUp";

export default {
  name: "Clients",
  components: {
    AddPropertyEnquiry,
    ClientsFollowUp
  },
  data() {
    return {
      btnLoading: false,
      followup: {
        comment: "",
        client_uuid: "",
        action_purpose: "",
        action_datetime: ""
      },
      rules: {
        comment: [
          {
            required: true,
            message: "message is required",
            trigger: "blur"
          }
        ]
      },
      query: {
        page: 1
      },
      showFollowUpModal: false,
      showFollowupTable: false,
      selectedClientName: "",
      selectedClientId: null,
      showAddEnquiryModal: false,
      tableLoading: false,
      tableData: [],
      search: "",
      currentPage: 1,
      total: 0
    };
  },
  created() {
    this.getClients();
  },
  methods: {
    getClients() {
      this.tableLoading = true;
      clientsApi
        .getClients(this.query)
        .then(response => {
          let clients = response.data.map(function(client) {
            client.name = client.first_name + " " + client.last_name;
            return client;
          });

          this.tableData = clients;
          this.total = response.pagination.total;

          this.tableLoading = false;
        })
        .catch(error => this.errorMessage(error.message));
    },
    showEnquiryModal(clientId, name) {
      this.selectedClientName = `Create enquiry for ${name}`;
      this.selectedClientId = clientId;
      this.showAddEnquiryModal = true;
    },
    followUp(clientId) {
      this.followup.client_uuid = clientId;
      this.showFollowUpModal = true;
    },
    viewFollowUps(clientId, name) {
      console.log(clientId);
      this.selectedClientName = `Follow up for ${name}`;
      this.selectedClientId = clientId;
      this.showFollowupTable = true;
    },
    addClientFollowup() {
      this.btnLoading = true;
      this.$refs["followup"].validate(valid => {
        if (valid) {
          clientsApi
            .addClientFollowUp(this.followup)
            .then(response => {
              console.log(response);
              this.btnLoading = false;
              this.showFollowUpModal = false;
              this.successMessage("Follow up added successfully");
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.query.page = page;
      this.getClients();
    },
    closeDialog() {
      this.showAddEnquiryModal = false;
    }
  }
};
</script>
