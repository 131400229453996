const state = {
  notifications: [],
  notify_count: null
}

const getters = {
  getNotifications: (state) => state.notifications,
  getNotifyCount: (state) => state.notify_count
}

const actions = {
  add_notification({ commit }, data) {
    commit('GET_NOTIFICATIONS', data);
  },
  close_notification({ commit }, notificationIndex) {
    commit('CLOSE_NOTIFICATION', notificationIndex)
  },
  remove_all_notification({ commit }) {
    commit('CLEAR_NOTIFICATIONS')
  }
}

const mutations = {
  GET_NOTIFICATIONS: (state, response) => {
    state.notifications.push(response)
    state.notify_count = state.notifications.length
  },
  CLOSE_NOTIFICATION: (state, i) => {
    state.notifications.splice(i, 1);
    state.notify_count = state.notifications.length;
    if (state.notify_count == 0) state.notify_count = null;
  },
  CLEAR_NOTIFICATIONS: (state) => {
    state.notifications = []
    state.notify_count = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}