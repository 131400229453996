<template>
  <div>
    <div class="mb-2 mt-1">
      <el-input placeholder="search" v-model="search" class="mt-4 full-width">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>

    <el-card>
      <el-table
        :data="
          tableData.filter(
            data => !search || data.name.toLowerCase().includes(search.toLowerCase())
          )
        "
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <p><b>Info: </b> {{ props.row.description }}</p>
            <p>
              <b>Source: </b> <el-tag size="small">{{ props.row.source }}</el-tag>
            </p>
            <p><b>Garages: </b> {{ props.row.garages }}</p>
            <p><b>Bedrooms: </b> {{ props.row.bedrooms }}</p>
            <p><b>Bathrooms: </b> {{ props.row.bathrooms }}</p>
            <p><b>Move-in date: </b> {{ props.row.movein }}</p>
            <p><b>Inquiry date: </b> {{ getDate(props.row.created_at) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Client Info" width="220px">
          <template slot-scope="props">
            <div class="clickable" @click="showClient(props.row.client)">
              <span class="d-block">
                <i class="el-icon-user"></i>
                <b> {{ props.row.name }}</b>
              </span>
              <span class="d-block">
                <i class="el-icon-eleme"></i>
                {{ props.row.email }}
              </span>
              <span class="d-block">
                <i class="el-icon-phone"></i>
                {{ props.row.contact }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Property Type" width="120" align="center">
          <template slot-scope="props">
            <span class="d-block">
              {{ props.row.type }}
            </span>

            <span class="d-block mt-1">
              <el-tag size="small" type="success">For {{ props.row.category }}</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="View Time" prop="viewTime" width="100" align="center" />
        <el-table-column label="Status" align="center" width="120">
          <template slot-scope="props">
            <el-tag size="small" type="primary">{{ props.row.status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Location">
          <template slot-scope="props">
            <span class="d-block">
              {{ props.row.location }}
            </span>
            <span class="d-block"> <b>Region: </b>{{ props.row.region }} </span>
          </template>
        </el-table-column>
        <el-table-column label="Budget" width="150" align="center">
          <template slot-scope="props">
            <el-tag size="mini" type="primary">Min: GH₵ {{ props.row.pricefrom }}</el-tag>
            <el-tag size="mini" type="primary">Max: GH₵ {{ props.row.priceto }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="150" class="mob_table_column">
          <template slot-scope="props">
            <el-tooltip class="item" effect="dark" content="Edit inquiry details" placement="top">
              <el-button
                type="primary"
                size="mini"
                plain
                icon="el-icon-edit"
                @click="editEnquiry(props.row)"
                v-if="userCan('can_update_inquiry')"
              />
            </el-tooltip>
            <el-tooltip
              v-if="type !== 'confirmed'"
              class="item"
              effect="dark"
              content="Confirm inquiry as request"
              placement="top"
            >
              <el-button
                type="success"
                size="mini"
                icon="el-icon-s-promotion"
                @click="confirmEnquiry(props.row.uuid)"
                v-if="userCan('can_add_inquiry_to_property_request')"
              />
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Delete inquiry" placement="top">
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="deleteEnquiry(props.row.uuid)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <div style="text-align: center;margin-top: 10px; padding-bottom:10px;">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[15, 30, 50]"
        :page-size="15"
        layout="prev, pager, next, sizes, total"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- edit enquiry dialog -->
    <el-dialog
      :visible.sync="showEditEnquiryModal"
      width="50%"
      :before-close="closeModal"
      custom-class="mobile-modal"
    >
      <template slot="title">
        <h2>Edit Property Enquiry</h2>
        <p>All fields should be filled for accurate property search.</p>
      </template>
      <EditPropertyEnquiry
        v-if="showEditEnquiryModal"
        v-on:editedEnquiry="enquiryEdited"
        :enquiry.sync="selectedEnquiry"
      />
    </el-dialog>

    <!-- show client dialog -->
    <el-dialog
      :visible.sync="showClientModal"
      width="25%"
      :before-close="closeModal"
      title="Client Details"
      custom-class="mobile-modal"
    >
      <ShowClientDetails v-if="showClientModal" :client.sync="selectedClient" />
    </el-dialog>
  </div>
</template>

<script>
import inquiriesApi from "@/api/inquiries";
import ShowClientDetails from "@/components/ShowClientDetails";
import EditPropertyEnquiry from "@/components/enquiries/EditPropertyEnquiry";

export default {
  name: "EnquiryList",
  props: {
    type: String
  },
  components: {
    EditPropertyEnquiry,
    ShowClientDetails
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      selectedEnquiry: null,
      selectedClient: null,
      showEditEnquiryModal: false,
      showAddEnquiryModal: false,
      showClientModal: false,
      search: "",
      tableData: [],
      tableLoading: false,
      query: {
        status: "",
        page: 1
      }
    };
  },
  created() {
    this.getPropertyEnquiries();
  },
  methods: {
    getPropertyEnquiries() {
      this.tableLoading = true;
      this.query.status = this.type;
      inquiriesApi
        .getEnquiryByStatus(this.query)
        .then(response => {
          this.loadTable(response);
        })
        .catch(error => {
          this.tableLoading = false;
          this.errorMessage(error.message);
        });
    },
    loadTable(response) {
      let self = this;
      let table = response.data.map(function(inquiry) {
        inquiry.status = inquiry.status.toUpperCase();
        inquiry.name = inquiry.client.first_name + " " + inquiry.client.last_name;
        inquiry.email = inquiry.client.email;
        inquiry.viewTime = self.getDate(inquiry.viewtime);
        inquiry.contact = inquiry.client.contact;
        return inquiry;
      });
      this.tableData = table;
      this.total = response.pagination.total;
      this.tableLoading = false;
      this.$emit("loaded", { type: this.type, total: this.total });
    },
    editEnquiry(enquiry) {
      this.selectedEnquiry = enquiry;
      this.showEditEnquiryModal = true;
    },
    confirmEnquiry(enquiryId) {
      this.$confirm("This will permanently make this enquiry a property request", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.makeEnquiryRequest(enquiryId);
        })
        .catch(() => {
          this.infoMessage("Cancelled");
        });
    },
    makeEnquiryRequest(id) {
      console.log(id);
      let enquiryUUID = {
        property_inquiry_uuid: id
      };
      inquiriesApi
        .confirmAnEnquiry(enquiryUUID)
        .then(response => {
          this.successNotification("Success", response.message);
          this.$router.go();
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    confirmDelete(enquiryId) {
      this.$confirm("This will permanently delete enquiry", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.deleteEnquiry(enquiryId);
        })
        .catch(() => {
          this.infoMessage("Cancelled");
        });
    },
    deleteEnquiry(id) {
      inquiriesApi
        .deleteEnquiry(id)
        .then(response => {
          this.successNotification("", response.message);
          this.getPropertyEnquiries();
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    showClient(client) {
      this.selectedClient = client;
      this.showClientModal = true;
    },
    closeModal(done) {
      done();
    },
    enquiryAdded() {
      this.showAddEnquiryModal = false;
      this.getPropertyEnquiries();
    },
    enquiryEdited() {
      this.showEditEnquiryModal = false;
      this.getPropertyEnquiries();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.query.page = page;
      this.getPropertyEnquiries();
    }
  }
};
</script>
