import apiService from './api'
import config from '../config'

export default {
  name: 'brokersApi',
  components: {
    apiService
  },
  addAreaToBroker(area) {
    let url = config.BROKER_AREA_URL + '/areas'
    return apiService.post(url, area)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  assignCollaborator(collaborator) {
    let url = config.URL + 'assign_broker_collaborator';
    return apiService.post(url, collaborator)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  assignPropertyToCollaborator(formDetails) {
    let url = config.URL + 'broker_assign/collaborators_property_requests';
    return apiService.post(url, formDetails)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getAssignedAreas(brokerId) {
    let url = config.URL + "broker/areas"
    return apiService.get(url, brokerId)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getBrokerCollaborators(brokerId) {
    let url = config.URL + "broker/collaborators"
    return apiService.get(url, brokerId)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}