<template>
  <div>
    <el-form
      label-position="top"
      label-width="100px"
      :model="enquiryDetails"
      :rules="rules"
      ref="enquiryDetails"
    >
      <el-tabs v-model="activeTab" class="mt-4 px-2" stretch type="card">
        <el-tab-pane name="property">
          <span slot="label"><i class="el-icon-office-building"></i> Property Info</span>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="Property Type" prop="property_type">
                <el-select v-model="enquiryDetails.type" class="full-width">
                  <el-option
                    v-for="p in propertyTypes"
                    :key="p.id"
                    :label="p.type"
                    :value="p.type"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Category" prop="category">
                <el-select v-model="enquiryDetails.category" class="full-width">
                  <el-option
                    v-for="p in propertyCategory"
                    :key="p.id"
                    :label="p.category"
                    :value="p.category"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="Choose Region" prop="region">
                <el-select v-model="enquiryDetails.region" class="full-width">
                  <el-option
                    v-for="region in regions"
                    :key="region"
                    :label="region"
                    :value="region"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="Location" prop="location">
                <el-input v-model="enquiryDetails.location" placeholder="Spintex" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" v-show="!isLand">
            <el-col :span="12">
              <el-form-item label="Number of Garages">
                <el-input style="width:100%" v-model="enquiryDetails.garages" placeholder="1" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" v-show="!isLand">
            <el-col :span="12">
              <el-form-item label="Bedrooms" prop="bedrooms">
                <el-input type="number" v-model="enquiryDetails.bedrooms" placeholder="4" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Bathrooms" prop="bathrooms">
                <el-input type="number" v-model="enquiryDetails.bathrooms" placeholder="2" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="18" :lg="18" v-show="isLand">
              <el-form-item label="Acres">
                <el-input type="number" v-model="enquiryDetails.size">
                  <template slot="append">acres</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="18" :lg="18">
              <el-form-item label="Extra Information">
                <el-input
                  type="textarea"
                  placeholder="additional information on property mentioned by client"
                  v-model="enquiryDetails.description"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <br />
          <div class="mt-3">
            <el-button class="full-width" type="primary" @click="handleContinue('budget')"
              >Continue</el-button
            >
          </div>
        </el-tab-pane>

        <!-- Budget Info -->
        <el-tab-pane name="budget">
          <span slot="label"><i class="el-icon-key"></i> Budget Info</span>
          <h3 class="ml-1">How much they're willing to spend?</h3>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="12">
              <el-form-item label="Minimum">
                <el-input v-model="enquiryDetails.pricefrom" placeholder="0">
                  <template slot="prepend">GH₵</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12">
              <el-form-item label="Maximum">
                <el-input v-model="enquiryDetails.priceto" placeholder="0">
                  <template slot="prepend">GH₵</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" v-show="!isLand">
              <el-form-item label="Move in Period">
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  editable
                  type="date"
                  placeholder="move in date"
                  style="width: 100%;"
                  v-model="enquiryDetails.movein"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12">
              <el-form-item label="Viewing Date">
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  editable
                  type="date"
                  placeholder="viewing date"
                  style="width: 100%;"
                  v-model="enquiryDetails.viewtime"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <br />
          <div class="mt-3">
            <el-button
              class="full-width"
              type="primary"
              @click="handleContinue('client')"
              v-if="clientId == 'noId'"
              >Continue</el-button
            >
            <div class="flex-justify-end" v-else>
              <el-button
                type="primary"
                class="full-width"
                :loading="btnLoading"
                @click="createEnquiryForClient"
                icon="el-icon-plus"
                >Add Enquiry</el-button
              >
            </div>
          </div>
        </el-tab-pane>

        <!-- Clients info -->
        <el-tab-pane name="client" v-if="clientId == 'noId'">
          <span slot="label"><i class="el-icon-user"></i> Client Info</span>

          <el-form
            label-position="top"
            label-width="100px"
            :model="clientDetails"
            :rules="clientRules"
            ref="clientDetails"
          >
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item label="First Name" prop="first_name">
                  <el-input v-model="clientDetails.first_name" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Last Name" prop="last_name">
                  <el-input v-model="clientDetails.last_name" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Email" prop="email">
                  <el-input v-model="clientDetails.email" placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Marital Status" prop="marital_status">
                  <el-select v-model="clientDetails.marital_status" style="width:100%;">
                    <el-option label="Single" value="single"></el-option>
                    <el-option label="Married" value="married"></el-option>
                    <el-option label="Divorced" value="divorced"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Profession" prop="profession">
                  <el-input v-model="clientDetails.profession" placeholder="" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Age Range" prop="age_range">
                  <el-select v-model="clientDetails.age_range" style="width:100%;">
                    <el-option
                      v-for="age in ageRange"
                      :key="age"
                      :label="age"
                      :value="age"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :span="9">
                <el-form-item label="Contact" prop="contact">
                  <el-input v-model="clientDetails.contact" placeholder="0244153346" />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="Gender" prop="gender">
                  <el-select v-model="clientDetails.gender" style="width:100%;">
                    <el-option label="Male" value="male"></el-option>
                    <el-option label="Female" value="female"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Source">
                  <el-select v-model="enquiryDetails.source" style="width:100%;">
                    <el-option label="Walk-in" value="walk-in"></el-option>
                    <el-option label="Call" value="call"></el-option>
                    <el-option label="Online" value="online"></el-option>
                    <el-option label="Google" value="google"></el-option>
                    <el-option label="Facebook" value="facebook"></el-option>
                    <el-option label="Linkedin" value="linkedin"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

          <div class="mt-2 flex-justify-end">
            <el-button
              type="primary"
              class="full-width"
              :loading="btnLoading"
              @click="addEnquiry"
              icon="el-icon-plus"
              >Add Enquiry</el-button
            >
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import propertyApi from "@/api/properties";
import inquiriesApi from "@/api/inquiries";
import clientsApi from "@/api/clients";

export default {
  props: {
    clientId: String
  },
  data() {
    return {
      btnLoading: false,
      activeTab: "property",
      propertyTypes: [],
      propertyCategory: [],
      ageRange: ["16-25", "26-35", "36-45", "46-55", "56-65", "66-75", "76-85", "86-95", "96-105"],
      clientDetails: {
        first_name: "",
        last_name: "",
        contact: "",
        email: "",
        gender: "male",
        marital_status: "",
        age_range: "",
        profession: "enterpreneur"
      },
      enquiryDetails: {
        client_uuid: "",
        source: "call",
        type: "",
        category: "",
        region: "",
        size: "",
        location: "",
        bedrooms: "",
        bathrooms: "",
        garages: "",
        description: "",
        pricefrom: "",
        priceto: "",
        movein: "",
        viewtime: "",
        status: "pending"
      },
      rules: {
        type: [
          {
            required: true,
            message: "Please select a type",
            trigger: ["blur", "change"]
          }
        ],
        source: [
          {
            required: true,
            message: "Please select a source",
            trigger: ["blur", "change"]
          }
        ],
        category: [
          {
            required: true,
            message: "Please select a category",
            trigger: ["blur", "change"]
          }
        ],
        region: [
          {
            required: true,
            message: "Please select a region",
            trigger: ["blur", "change"]
          }
        ],
        size: [
          {
            required: true,
            message: "Please select a size",
            trigger: ["blur", "change"]
          }
        ],
        location: [
          {
            required: true,
            message: "Please select a location",
            trigger: ["blur", "change"]
          }
        ],
        bedrooms: [
          {
            required: true,
            message: "Please select a bedrooms",
            trigger: ["blur", "change"]
          }
        ],
        pricefrom: [
          { max: 20, message: "Length shouldn't be 20 or more", trigger: ["blur", "change"] }
        ],
        priceto: [
          { max: 20, message: "Length shouldn't be 20 or more", trigger: ["blur", "change"] }
        ]
      },
      clientRules: {
        first_name: [
          {
            required: true,
            message: "Please select a name",
            trigger: ["blur", "change"]
          }
        ],
        last_name: [
          {
            required: true,
            message: "Please select a last name",
            trigger: ["blur", "change"]
          }
        ],
        marital_status: [
          {
            required: true,
            message: "Please select married status",
            trigger: ["blur", "change"]
          }
        ],
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: ["blur", "change"]
          }
        ],
        profession: [
          {
            required: true,
            message: "Proffession is required",
            trigger: ["blur", "change"]
          }
        ],
        contact: [
          {
            required: true,
            message: "Contact field is required",
            trigger: ["blur", "change"]
          }
        ],
        age_range: [
          {
            required: true,
            message: "Please select an age range",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      regions: "getRegions"
    }),
    isLand() {
      return this.enquiryDetails.type == "Land";
    }
  },
  created() {
    this.getPropertyTypes();
    this.getPropertyCategory();
    console.log(this.clientId);
    if (this.clientId !== "noId") this.enquiryDetails.client_uuid = this.clientId;
  },
  methods: {
    getPropertyTypes() {
      propertyApi
        .getpropertyTypes()
        .then(response => {
          this.propertyTypes = response.data;
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    getPropertyCategory() {
      propertyApi
        .getpropertyCategories()
        .then(response => {
          this.propertyCategory = response.data;
        })
        .catch(error => {
          this.btnLoading = false;
          this.errorMessage(error.message);
        });
    },
    addEnquiry() {
      console.log("runnnnn");
      this.btnLoading = true;
      this.$refs["enquiryDetails"].validate(valid => {
        if (valid) {
          this.createClient();
        } else {
          this.errorMessage("Please fill all fields to add a an enquiry.");
          this.btnLoading = false;
          return false;
        }
      });
    },
    createClient() {
      this.$refs["clientDetails"].validate(valid => {
        if (valid) {
          clientsApi
            .addClient(this.clientDetails)
            .then(response => {
              this.enquiryDetails.client_uuid = response.data.uuid;
              this.createEnquiry();
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.errorMessage("Please fill all related required client fields.");
          this.btnLoading = false;
          return false;
        }
      });
    },
    createEnquiry() {
      console.log("runnnnn");
      inquiriesApi
        .addInquiry(this.enquiryDetails)
        .then(() => {
          this.btnLoading = false;
          this.successNotification("Success", "Enquiry added successfully");
          this.$emit("addedEnquiry");
        })
        .catch(error => {
          this.btnLoading = false;
          this.errorMessage(error.message);
        });
    },
    createEnquiryForClient() {
      this.btnLoading = true;
      inquiriesApi
        .addInquiry(this.enquiryDetails)
        .then(() => {
          this.btnLoading = false;
          this.successNotification("Success", "Enquiry added successfully");
          this.$emit("addedEnquiry");
        })
        .catch(error => {
          this.btnLoading = false;
          this.errorMessage(error.message);
        });
    },
    handleContinue(nextTab) {
      this.activeTab = nextTab;
    }
  }
};
</script>
