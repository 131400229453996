import apiService from './api'
import config from '../config'

export default {
  name: 'clientsApi',
  components: {
    apiService
  },
  getClients(query) {
    return apiService.get(config.CLIENTS_URL, query)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getClient(id) {
    let url = config.CLIENTS_URL + '/' + id
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addClient(clientData) {
    let url = config.CLIENTS_URL
    return apiService.post(url, clientData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  updateClient(clientData) {
    let url = config.CLIENTS_URL + '/' + clientData.uuid
    return apiService.update(url, clientData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addClientFollowUp(followUpData) {
    let url = config.FOLLOW_UP_URL
    return apiService.post(url, followUpData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getClientsFollowUp(query) {
    return apiService.get(config.FOLLOW_UP_URL, query)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  updateClientsFollowUp(data) {
    let url = config.FOLLOW_UP_URL + '/' + data.follow_up_uuid
    return apiService.update(url, data)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  deleteClientsFollowUp(id) {
    let url = config.FOLLOW_UP_URL + '/' + id
    return apiService.delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
}