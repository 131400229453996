<template>
  <div>
    <div class="mb-2 flex-justify-between">
      <h2>{{ title }}</h2>

      <el-button
        icon="el-icon-plus"
        plain
        type="primary"
        @click="showAddUserModal = true"
        v-if="userCan('can_add_new_user')"
      >
        Add New User
      </el-button>
    </div>

    <div>
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane name="system">
          <span slot="label"> {{ systemUsersTab }}</span>
          <UsersList type="system" v-on:loaded="userLoaded" />
        </el-tab-pane>

        <el-tab-pane name="collaborator">
          <span slot="label"> {{ collaboratorTab }}</span>
          <UsersList type="collaborator" v-on:loaded="userLoaded" />
        </el-tab-pane>

        <el-tab-pane name="broker">
          <span slot="label"> {{ brokerTab }}</span>
          <UsersList type="broker" v-on:loaded="userLoaded" />
        </el-tab-pane>

        <el-tab-pane name="others">
          <span slot="label"> {{ othersTab }}</span>
          <UsersList type="others" v-on:loaded="userLoaded" />
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      :visible.sync="showAddUserModal"
      width="30%"
      title="New User"
      custom-class="mobile-modal"
    >
      <AddNewUser v-on:closeModal="userAdded" />
    </el-dialog>
  </div>
</template>

<script>
import UsersList from "@/components/users/UsersList";
import AddNewUser from "@/components/users/AddNewUser";

export default {
  name: "Users",
  components: {
    UsersList,
    AddNewUser
  },
  data() {
    return {
      activeTab: "system",
      othersTab: "Others",
      othersTotal: 0,
      collaboratorTab: "Collaborators",
      collabTotal: 0,
      brokerTab: "Brokers",
      brokerTotal: 0,
      systemUsersTab: "System Users",
      systemTotal: 0,
      title: "Users",
      showAddUserModal: false
    };
  },
  methods: {
    userLoaded(args) {
      let type = args.type;
      let total = args.total;

      if (type == "others") {
        this.othersTab = "Others (" + total + ")";
        this.othersTotal = total;
      } else if (type == "collaborator") {
        this.collaboratorTab = "Collaborator (" + total + ")";
        this.collabTotal = total;
      } else if (type == "broker") {
        this.brokerTab = "Brokers (" + total + ")";
        this.brokerTotal = total;
      } else if (type == "system") {
        this.systemUsersTab = "System Users (" + total + ")";
        this.systemTotal = total;
      }

      let usersTotal = this.brokerTotal + this.systemTotal + this.collabTotal + this.othersTotal;
      this.title = "Users (" + usersTotal + ")";
    },
    userAdded() {
      this.showAddUserModal = false;
      this.$router.go();
      this.successNotification("Success", "User added successfully");
    }
  }
};
</script>
