<template>
  <div>
    <el-form :model="userDetails" :rules="rules" ref="userDetails">
      <el-row :gutter="10">
        <el-col :span="24" v-if="isCollaborator">
          <div style="text-align:center;">
            <el-avatar :size="70" :src="photoBase64File"></el-avatar>
            <div>
              <input
                type="file"
                ref="file"
                style="display: none"
                @change="uploadImage($event, 'photo')"
              />
              <el-button @click="$refs.file.click()" type="text" icon="el-icon-edit">
                <b style="color:#2fa512;"> Upload Photo</b></el-button
              >
            </div>
          </div>
        </el-col>

        <el-col :span="24" v-if="isCollaborator">
          <el-form-item>
            <el-select
              v-model="collaboratorInfo.id_type"
              class="full-width"
              placeholder="Select ID type"
            >
              <el-option v-for="id in idTypes" :key="id" :label="id" :value="id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col v-if="isCollaborator">
          <el-form-item>
            <label>Upload scanned ID type</label> <br />
            <input type="file" @change="uploadImage($event, 'scannedId')" />
          </el-form-item>
        </el-col>

        <!--  user edit info -->
        <el-col :span="12">
          <el-form-item prop="first_name">
            <el-input v-model="userDetails.first_name" placeholder="Enter first name" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="last_name">
            <el-input v-model="userDetails.last_name" placeholder="Enter last name" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="email">
            <el-input v-model="userDetails.email" placeholder="youremail@email.com" />
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item prop="contact">
            <el-input v-model="userDetails.contact" placeholder="0244173346" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <el-select v-model="userDetails.gender" style="width:100%;" placeholder="gender">
              <el-option label="Male" value="male"></el-option>
              <el-option label="Female" value="female"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="password">
            <el-input type="password" v-model="userDetails.password" placeholder="*********" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="password_confirm">
            <el-input
              type="password"
              v-model="userDetails.password_confirm"
              placeholder="confirm password"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        class="full-width"
        type="primary"
        icon="el-icon-edit"
        :loading="btnLoading"
        @click="editUser"
        >Update Details</el-button
      >
    </el-form>
  </div>
</template>

<script>
import usersApi from "@/api/users";
import collaboratorApi from "@/api/collaborators";

export default {
  props: {
    user: Object
  },
  data() {
    return {
      idTypes: ["voter", "passport", "drivers"],
      btnLoading: false,
      photoBase64File: "",
      collaboratorInfo: {
        id_type: "",
        photo: null,
        scanned_id_photo: null,
        user_uuid: ""
      },
      userDetails: {
        uuid: null,
        first_name: "",
        last_name: "",
        contact: "",
        email: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "Select user name",
            trigger: "blur"
          }
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Email is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    isCollaborator() {
      return this.user.role == "collaborator";
    }
  },
  created() {
    this.userDetails = this.user;
    this.collaboratorInfo.user_uuid = this.user.uuid;
  },
  methods: {
    editUser() {
      this.btnLoading = true;
      this.$refs["userDetails"].validate(valid => {
        if (valid) {
          console.log(this.userDetails);
          usersApi
            .updateUser(this.userDetails)
            .then(() => {
              this.btnLoading = false;
              this.successNotification("Success", "User edited successfully");
              if (this.user.role == "collaborator") this.saveCollaboratorFiles();
              this.$emit("edited");
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    },
    uploadImage(e, type) {
      let self = this;
      let file = e.target.files[0];
      const files = e.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = file => {
        console.log(file);
        if (type == "photo") {
          self.collaboratorInfo.photo = files[0];
          self.photoBase64File = reader.result;
        } else if (type == "scannedId") {
          self.collaboratorInfo.scanned_id_photo = files[0];
        }
      };

      console.log(this.collaboratorInfo);
    },
    saveCollaboratorFiles() {
      const formData = new FormData();
      formData.append("photo", this.collaboratorInfo.photo);
      formData.append("scanned_id_photo", this.collaboratorInfo.scanned_id_photo);
      formData.append("id_type", this.collaboratorInfo.id_type);
      formData.append("user_uuid", this.collaboratorInfo.user_uuid);

      console.log(formData);

      collaboratorApi
        .saveFiles(formData)
        .then(response => {
          console.log(response);
          this.successNotification("Success", "Files saved successfully");
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    }
  }
};
</script>
