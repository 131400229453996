<template>
  <div>
    <el-form :model="assignReqDetails" :rules="rules" ref="assignReqDetails">
      <el-form-item prop="user_uuid">
        <el-select
          v-model="assignReqDetails.user_uuid"
          class="full-width"
          placeholder="select collaborator"
        >
          <el-option
            v-for="c in collaborators"
            :key="c.id"
            :label="c.name"
            :value="c.uuid"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-button
        class="full-width"
        type="primary"
        icon="el-icon-user"
        :loading="btnLoading"
        @click="assignToCollaborator"
        >Assign to collaborator</el-button
      >
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import usersApi from "@/api/users";
import brokersApi from "@/api/brokers";

export default {
  props: {
    requestId: String
  },
  data() {
    return {
      btnLoading: false,
      collaborators: [],
      assignReqDetails: {
        supervisor_uuid: "",
        property_request_uuid: "",
        user_uuid: ""
      },
      rules: {}
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    })
  },
  created() {
    this.assignReqDetails.property_request_uuid = this.requestId;
    this.assignReqDetails.supervisor_uuid = this.user.uuid;
    this.getCollaborators();
  },
  methods: {
    getCollaborators() {
      usersApi
        .getUsers()
        .then(response => {
          let users = response.data.map(function(user) {
            user.role = user.role.length === 0 ? "no role" : user.role[0].role;
            user.name = user.first_name + " " + user.last_name;
            return user;
          });

          this.collaborators = users.filter(user => user.role == "collaborator");
        })
        .catch(error => this.errorMessage(error.message));
    },
    assignToCollaborator() {
      this.btnLoading = true;
      brokersApi
        .assignPropertyToCollaborator(this.assignReqDetails)
        .then(() => {
          this.btnLoading = false;
          this.successNotification("Success", "Peoperty assigned to collaborator");
          this.$emit("closeModal");
        })
        .catch(error => this.errorMessage(error.message));
    }
  }
};
</script>
