import apiService from './api'
import config from '../config'

export default {
  name: 'messagesApi',
  components: {
    apiService
  },
  getMessages() {
    let url = config.MESSAGES_URL;
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getMessageChannels() {
    let url = config.URL + 'message_channel_settings'
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  createMessage(messageDetails) {
    let url = config.MESSAGES_URL;
    return apiService.post(url, messageDetails)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  setChannelDefault(id) {
    let url = config.URL + 'message_channel_settings/' + id + '/set_default';
    return apiService.update(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}