<template>
  <div>
    <h4>
      You can either revoke a role from a user or add another role to a user.
    </h4>
    <h3 class="mt-2">Add role to user</h3>
    <el-form :model="userRoleDetails" :rules="rules" ref="userRoleDetails">
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item prop="role_id">
            <el-select
              v-model="userRoleDetails.role_id"
              class="full-width"
              placeholder="Select role"
            >
              <el-option v-for="role in roles" :key="role.id" :label="role.role" :value="role.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        class="full-width"
        type="primary"
        icon="el-icon-user"
        :loading="btnLoading"
        @click="assignRole"
        >Add role to user</el-button
      >
    </el-form>
  </div>
</template>

<script>
import rolesApi from "@/api/roles";

export default {
  props: {
    userId: String
  },
  data() {
    return {
      roles: [],
      btnLoading: false,
      revokeRoleDetails: {
        user_uuid: "",
        role_id: ""
      },
      userRoleDetails: {
        user_uuid: "",
        role_id: ""
      },
      rules: {
        role: [
          {
            required: true,
            message: "Select a role",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.userRoleDetails.user_uuid = this.userId;
    this.getRoles();
  },
  methods: {
    getRoles() {
      rolesApi
        .getAllRoles()
        .then(response => {
          this.roles = response.data;
        })
        .catch(error => this.errorMessage(error.message));
    },
    assignRole() {
      this.$refs["userRoleDetails"].validate(valid => {
        if (valid) {
          rolesApi
            .assignUserRole(this.userRoleDetails)
            .then(() => {
              this.successNotification("Success", "Role assigned successfully");
            })
            .catch(error => this.errorMessage(error.message));
        }
      });
    }
  }
};
</script>
