const state = {
  user: null,
  role: '',
  loggedIn: false,
  token: '',
  regions: [
    'Greater Accra',
  ],
  permissions: [],
}

const getters = {
  getUser: (state) => state.user,
  getRole: (state) => state.role,
  loggedIn: (state) => state.loggedIn,
  getToken: (state) => state.token,
  getRegions: (state) => state.regions,
  getPermissions: (state) => state.permissions
}

const actions = {
  login({ commit }, response) {
    commit('LOGIN_SUCCESS', response);
  },
  loginFailed({ commit }) {
    commit('LOGIN_FAILURE');
  },
  logout({ commit }) {
    commit('LOGOUT');
  },
  get_user({ commit }, response) {
    commit('USER_SUCCESS', response);
  },
}

const mutations = {
  LOGIN_SUCCESS: (state, response) => {
    response.permissions.forEach(element => {
      state.permissions.push(element.name)
    });
    state.token = response.token
    state.user = response.user
    state.role = response.roles[0]
    state.loggedIn = true
  },
  LOGIN_FAILURE: (state) => {
    state.token = ''
    state.user = null
    state.role = ''
    state.loggedIn = false
    state.permissions = []
  },
  LOGOUT: (state) => {
    state.user = null
    state.token = ''
    state.role = ''
    state.loggedIn = false
    state.permissions = []
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}