import apiService from './api'
import config from '../config'

export default {
  name: 'reviewsApi',
  components: {
    apiService
  },
  getReview(tripId) {
    let url = config.REVIEWS_URL + '?trip_uuid=' + tripId
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getAllReviews() {
    let url = config.REVIEWS_URL
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addReview(review) {
    let url = config.URL + 'review'
    return apiService.post(url, review)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
}