import Vue from 'vue';
import App from './App.vue';
import mixin from './mixins';
import router from './router';
import store from './store';
import eventbus from "./eventbus";

import '@/assets/css/base.css';
import './plugins/element-ui';

Vue.mixin(mixin);
Vue.config.productionTip = false;
Vue.prototype.$eventbus = eventbus;

import '@/plugins/pusher/index.js'

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
