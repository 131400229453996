<template>
  <div>
    <el-form :model="userDetails" :rules="rules" ref="userDetails">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="first_name">
            <el-input v-model="userDetails.first_name" placeholder="Enter first name" />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="last_name">
            <el-input v-model="userDetails.last_name" placeholder="Enter last name" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="email">
            <el-input v-model="userDetails.email" placeholder="youremail@email.com" />
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item prop="contact">
            <el-input v-model="userDetails.contact" placeholder="0244173346" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <el-select v-model="userDetails.gender" style="width:100%;" placeholder="gender">
              <el-option label="Male" value="male"></el-option>
              <el-option label="Female" value="female"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="password">
            <el-input v-model="userDetails.password" type="password" placeholder="********" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-input
              v-model="userDetails.confirm_password"
              type="password"
              placeholder="********"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        class="full-width"
        type="primary"
        icon="el-icon-plus"
        :loading="btnLoading"
        @click="addUser"
        >Create User</el-button
      >
    </el-form>
  </div>
</template>

<script>
import usersApi from "@/api/users";

export default {
  data() {
    return {
      btnLoading: false,
      userDetails: {
        first_name: "",
        last_name: "",
        contact: "",
        email: "",
        gender: "",
        password: "",
        confirm_password: ""
      },
      rules: {
        first_name: [
          {
            required: true,
            message: "Select user name",
            trigger: "blur"
          }
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Email is required",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "Please enter password",
            trigger: "blur"
          },
          {
            min: 6,
            message: "Length should be 6 or more characters",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    addUser() {
      this.btnLoading = true;
      this.$refs["userDetails"].validate(valid => {
        if (valid) {
          usersApi
            .addUser(this.userDetails)
            .then(() => {
              this.btnLoading = false;
              this.$emit("closeModal");
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    }
  }
};
</script>
