import apiService from './api'
import config from '../config'

export default {
  name: 'collaboratorApi',
  components: {
    apiService
  },
  getCollaboratorFiles() {
    let url = config.URL + 'collaborators_files';
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  saveFiles(fileFormData) {
    let url = config.COLLABORATOR_URL;
    return apiService.post(url, fileFormData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}