<template>
  <div>
    <el-form :model="repDetails" :rules="rules" ref="repDetails">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="name">
            <el-input v-model="repDetails.name" placeholder="Enter name" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="phone">
            <el-input v-model="repDetails.phone" placeholder="Enter phone number" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="email">
            <el-input v-model="repDetails.email" placeholder="youremail@email.com" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        class="full-width"
        type="primary"
        icon="el-icon-plus"
        :loading="btnLoading"
        @click="addRep"
        >Add Rep</el-button
      >
    </el-form>
  </div>
</template>

<script>
import propertiesApi from "@/api/properties";

export default {
  props: {
    requestId: String
  },
  data() {
    return {
      btnLoading: false,
      repDetails: {
        property_request_uuid: "",
        name: "",
        phone: "",
        email: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "enter name",
            trigger: "blur"
          }
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Email is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.repDetails.property_request_uuid = this.requestId;
  },
  methods: {
    addRep() {
      this.btnLoading = true;
      this.$refs["repDetails"].validate(valid => {
        if (valid) {
          propertiesApi
            .addRep(this.repDetails)
            .then(() => {
              this.btnLoading = false;
              this.successNotification("Success", "Rep added successfully");
              this.$emit("closeModal");
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    }
  }
};
</script>
