<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="8">
        <div style="text-align:center;">
          <el-avatar :size="90" :src="collaboratorFiles.photo"></el-avatar>
        </div>
      </el-col>
      <el-col :span="16">
        <div>
          <h3>{{ collaboratorFiles.user.name }}</h3>
          <span class="d-block">{{ collaboratorFiles.user.email }}</span>
          <span class="d-block">{{ collaboratorFiles.user.contact }}</span>
          <span class="d-block"><b>Type:</b> {{ collaboratorFiles.type }}</span>
          <span class="d-block">
            ID card type: <b>{{ collaboratorFiles.idType }}</b>
          </span>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="10" style="margin-top: 20px;">
      <el-col :xs="24" :sm="24" :md="12" :lg="12">
        <DashboardCard
          title="Trips"
          icon="el-icon-truck"
          boardClass="dashboard_card"
          color="info"
          :totalNumber="collaboratorFiles.trips"
        />
      </el-col>

      <el-col :xs="24" :sm="24" :md="12" :lg="12">
        <DashboardCard
          title="Enquiries"
          icon="el-icon-phone-outline"
          boardClass="dashboard_card3"
          color="success"
          :totalNumber="collaboratorFiles.requests"
        />
      </el-col>

      <el-col :span="24" style="margin-top: 20px;">
        <label> Picture of ID card</label>
        <div style="text-align:center;">
          <img :src="collaboratorFiles.scanned_id" alt="id card" width="100%" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import config from "../../config";
import usersApi from "@/api/users";
import dashboardApi from "@/api/dashboard";
import DashboardCard from "@/components/DashboardCard";

export default {
  name: "CollaboratorProfile",
  props: {
    user: Object
  },
  components: {
    DashboardCard
  },
  data() {
    return {
      collaboratorFiles: {
        type: "",
        photo: "",
        scanned_id: "",
        idType: "",
        trips: 0,
        requests: 0,
        user: {
          name: "",
          email: "",
          contact: ""
        }
      }
    };
  },
  created() {
    this.viewDetails();
    this.getInfo();
  },
  methods: {
    viewDetails() {
      usersApi
        .getUserFiles(this.user.uuid)
        .then(response => {
          this.collaboratorFiles.idType = response.data.id_type;
          this.collaboratorFiles.photo =
            config.HOST_NAME == "localhost"
              ? "https://staging.dwellysops.app/storage/" + response.data.photo
              : "https://api.dwellysops.app/storage/" + response.data.photo;
          this.collaboratorFiles.scanned_id =
            config.HOST_NAME == "localhost"
              ? "https://staging.dwellysops.app/storage/" + response.data.scanned_id
              : "https://api.dwellysops.app/storage/" + response.data.scanned_id;
          this.collaboratorFiles.type = response.data.type;
          this.collaboratorFiles.user = {};
          this.collaboratorFiles.user = this.user;
        })
        .catch(() => {
          this.errorMessage("Collaborator has no files yet");
          this.collaboratorFiles.idType = "none";
          this.collaboratorFiles.photo =
            "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png";
          this.collaboratorFiles.scanned_id =
            "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png";
          this.collaboratorFiles.type = "individual";
          this.collaboratorFiles.user = this.user;
        });
    },
    getInfo() {
      dashboardApi
        .getCollaboratorDashboard(this.user.uuid)
        .then(response => {
          this.collaboratorFiles.requests = response.data.total_requests;
          this.collaboratorFiles.trips = response.data.total_trips;
        })
        .catch(errors => {
          this.errorMessage(errors.message);
        });
    }
  }
};
</script>
