<template>
  <div>
    <div class="mb-2 flex-justify-between">
      <el-input placeholder="search deal" v-model="search" class="mt-4">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>

    <el-card>
      <el-table
        :data="tableData"
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <p>
              <b>Commision Taken From: </b>
              <el-tag size="mini" type="warning">{{ props.row.commission_taken_from }}</el-tag>
            </p>
            <br />
            <p><b>View property selected: </b></p>
            <span>
              <a :href="props.row.selected_property_listings" target="_blank">property</a>
            </span>

            <p class="mt-1"><b>View uploaded documents for property:</b></p>
            <span>
              <el-button
                @click="viewDocs(props.row.property_request.uuid)"
                type="text"
                icon="el-icon-picture-outline"
              >
                <b style="color:#2fa512;"> document</b></el-button
              >
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Dates">
          <template slot-scope="props">
            <span class="d-block" style="font-size:12px;">Appointement Date</span>
            <el-tag size="mini">{{ props.row.appointment_date_time }}</el-tag>
            <span class="d-block" style="font-size:12px;">Payment Date</span>
            <el-tag size="mini">{{ props.row.payment_date }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Pricing">
          <template slot-scope="props">
            <span class="d-block" style="font-size:12px;">Agreed Comm.</span>
            <el-tag size="mini">{{ props.row.agreed_commission }}</el-tag>
            <span class="d-block" style="font-size:12px;">Negotiated Price:</span>
            <el-tag size="mini">{{ props.row.negotiated_price }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Status" prop="status">
          <template slot-scope="props">
            <el-tag size="mini" :type="props.row.status == 'pending' ? 'warning' : 'success'">{{
              props.row.status
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Notes" prop="notes"> </el-table-column>
        <el-table-column label="Payment Plan">
          <template slot-scope="props">
            <el-tag
              size="mini"
              :type="props.row.payment_plan == 'pending' ? 'warning' : 'success'"
              >{{ props.row.payment_plan }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="Created on">
          <template slot-scope="props">
            <span class="d-block">
              {{ getDate(props.row.created_at) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="props">
            <el-tooltip class="item" effect="dark" content="Update info" placement="top">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="editDeal(props.row)"
              />
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Upload docs" placement="top">
              <el-button
                type="info"
                size="mini"
                icon="el-icon-upload"
                @click="showUploadDoc(props.row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <div style="text-align: center;margin-top: 10px; padding-bottom:10px;">
      <el-pagination
        background
        :current-page.sync="currentPage"
        :page-sizes="[15, 30, 60]"
        :page-size="15"
        layout="prev, pager, next, sizes, total"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- Update deal -->
    <el-dialog
      :visible.sync="showUpdateDealModal"
      width="45%"
      :before-close="closeModal"
      title="Update Deal"
      custom-class="mobile-modal"
    >
      <div>
        <el-form
          label-position="top"
          label-width="100px"
          :model="dealDetails"
          :rules="rules"
          ref="dealDetails"
        >
          <el-row :gutter="10">
            <el-col :md="12" :lg="12">
              <el-form-item label="Satus" prop="status">
                <el-select v-model="dealDetails.status" class="full-width">
                  <el-option v-for="st in status" :key="st" :label="st" :value="st"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12" :lg="12">
              <el-form-item label="Commission" prop="agreed_commission">
                <el-input
                  type="text"
                  v-model="dealDetails.agreed_commission"
                  placeholder="commision agreed"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="11" :lg="11">
              <el-form-item label="Payment Date">
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  editable
                  type="date"
                  placeholder="payment date"
                  style="width: 100%;"
                  v-model="dealDetails.payment_date"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :md="13" :lg="13">
              <el-form-item label="Appointment Date">
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  editable
                  type="date"
                  placeholder="appointment"
                  style="width: 100%;"
                  v-model="dealDetails.appointment_date_time"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="12" :lg="12">
              <el-form-item label="Payment Plan" prop="payment_plan">
                <el-select v-model="dealDetails.payment_plan" class="full-width">
                  <el-option label="Pending" value="pending"></el-option>
                  <el-option label="Installment" value="installment"></el-option>
                  <el-option label="One" value="one_time_payment"></el-option>
                  <el-option label="Declined" value="declined"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12" :lg="12">
              <el-form-item label="Negotiated Price">
                <el-input
                  type="number"
                  v-model="dealDetails.negotiated_price"
                  placeholder="40000.00"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="12" :lg="12">
              <el-form-item label="Commission Taken From">
                <el-select v-model="dealDetails.commission_taken_from" class="full-width">
                  <el-option
                    v-for="comm in commission_taken_from"
                    :key="comm"
                    :label="comm"
                    :value="comm"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :md="12" :lg="12">
              <el-form-item label="Feedback Date">
                <el-date-picker
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  editable
                  type="date"
                  placeholder="feedback"
                  style="width: 100%;"
                  v-model="dealDetails.get_feedback_on"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="24" :lg="24">
              <el-form-item label="Feedback">
                <el-input
                  type="textarea"
                  placeholder="additional relevant information"
                  v-model="dealDetails.notes"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <div class="mt-2">
            <el-button
              type="primary"
              class="full-width"
              :loading="btnLoading"
              @click="updateDeal"
              icon="el-icon-edit"
              >Update</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showUpload"
      width="30%"
      destroy-on-close
      :before-close="closedoc"
      custom-class="mobile-modal"
      title="Upload Docs"
    >
      <div>
        <el-form
          label-position="top"
          label-width="100px"
          :model="docUpload"
          :rules="rules"
          ref="docUpload"
        >
          <el-row>
            <el-col :md="24" :lg="24">
              <el-form-item label="Doc name" prop="document_name">
                <el-input
                  type="text"
                  v-model="docUpload.document_name"
                  placeholder="document name"
                />
              </el-form-item>
            </el-col>

            <el-col :md="24" :lg="24">
              <el-form-item label="Description">
                <el-input
                  type="textarea"
                  placeholder="additional relevant information"
                  v-model="docUpload.description"
                />
              </el-form-item>
            </el-col>

            <el-col :md="12" :lg="12">
              <input type="file" ref="file" style="display: none" @change="uploadImage($event)" />
              <div style="text-align:center;">
                <span style="border:1px dashed grey; padding:10px;">
                  <el-button @click="$refs.file.click()" type="text" icon="el-icon-upload">
                    <b style="color:#2fa512;"> Upload Property Docs</b></el-button
                  >
                </span>
              </div>
            </el-col>
            <el-col :md="12" :lg="12">
              <el-image style="width: 150px" :src="docBase64File" fit="contain">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-col>
          </el-row>
          <div class="mt-2">
            <el-button
              type="primary"
              class="full-width"
              :loading="btnLoading"
              @click="savePropertyDocs"
              icon="el-icon-plus"
              >Save document</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>

    <!-- doc table -->
    <el-dialog
      :visible.sync="showDocsTable"
      width="60%"
      destroy-on-close
      custom-class="mobile-modal"
      title="Uploaded property docs."
    >
      <PropertyRequestDocuments v-if="showDocsTable" :property_requestId="property_requestId" />
    </el-dialog>
  </div>
</template>

<script>
import propertySalesApi from "@/api/property_sales";
import PropertyRequestDocuments from "@/components/requests/PropertyRequestDocuments";
export default {
  name: "PropertySales",
  components: {
    PropertyRequestDocuments
  },
  data() {
    return {
      showDocsTable: false,
      property_requestId: "",
      docBase64File: "",
      showUpload: false,
      currentPage: 1,
      total: 0,
      commission_taken_from: ["pending", "buyer_owner", "owner", "buyer"],
      status: ["pending", "closed", "declined", "discussion"],
      search: "",
      tableData: [],
      btnLoading: false,
      tableLoading: false,
      showUpdateDealModal: false,
      dealDetails: {
        uuid: "",
        property_request_uuid: "",
        selected_property_listings: "HS-4",
        appointment_date_time: "",
        created_by: "",
        notes: "",
        status: "",
        payment_date: "",
        agreed_commission: "",
        get_feedback_on: "",
        commission_taken_from: "pending",
        payment_plan: "",
        negotiated_price: ""
      },
      docUpload: {
        property_request_uuid: "",
        description: "",
        document_name: "",
        file_name: ""
      },
      rules: {
        status: [
          {
            required: true,
            message: "status is required",
            trigger: "blur"
          }
        ],
        document_name: [
          {
            required: true,
            message: "doc. name is required",
            trigger: "blur"
          }
        ]
      },
      query: {
        page: 1,
        per_page: 20
      }
    };
  },
  created() {
    this.getpropertySales();
  },
  methods: {
    getpropertySales() {
      this.tableLoading = true;
      propertySalesApi
        .getpropertySales(this.query)
        .then(response => {
          this.loadTable(response);
        })
        .catch(error => this.errorMessage(error.message));
    },
    loadTable(response) {
      let sales = response.data.map(function(psales) {
        psales.notes = psales.notes == null ? "null" : psales.notes;
        psales.createdBy = psales.created_by.first_name + " " + psales.created_by.last_name;
        psales.appointment_date_time = psales.appointment_date_time
          ? psales.appointment_date_time
          : "none";
        psales.payment_date = psales.payment_date == null ? "none" : psales.payment_date;
        return psales;
      });

      this.tableData = sales;

      this.tableLoading = false;
      this.total = response.pagination.total;
    },
    editDeal(deal) {
      this.dealDetails.property_request_uuid = deal.property_request.uuid;
      this.dealDetails.uuid = deal.uuid;
      this.dealDetails.selected_property_listings = deal.selected_property_listings;
      this.dealDetails.appointment_date_time =
        deal.appointment_date_time == "none" ? null : deal.appointment_date_time;
      this.dealDetails.notes = deal.notes;
      this.dealDetails.get_feedback_on = deal.get_feedback_on;
      this.dealDetails.status = deal.status;
      this.dealDetails.payment_date = deal.payment_date == "none" ? null : deal.payment_date;
      this.dealDetails.agreed_commission = deal.agreed_commission;
      this.dealDetails.commission_taken_from = deal.commission_taken_from;
      this.dealDetails.payment_plan = deal.payment_plan;
      this.dealDetails.negotiated_price = deal.negotiated_price;

      this.showUpdateDealModal = true;
    },
    updateDeal() {
      this.btnLoading = true;
      propertySalesApi
        .updatePropertySales(this.dealDetails)
        .then(() => {
          this.btnLoading = false;
          this.successMessage("Deal updated successfully");
          this.showUpdateDealModal = false;
          this.getpropertySales();
        })
        .catch(error => {
          this.errorMessage(error.message);
          this.btnLoading = false;
        });
    },
    showUploadDoc(deal) {
      this.docUpload.property_request_uuid = deal.property_request.uuid;
      this.showUpload = true;
    },
    uploadImage(e) {
      let self = this;
      let file = e.target.files[0];
      const files = e.target.files;
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = file => {
        console.log(file);
        self.docUpload.file_name = files[0];
        self.docBase64File = reader.result;
      };
    },
    savePropertyDocs() {
      const formData = new FormData();
      formData.append("file_name", this.docUpload.file_name);
      formData.append("document_name", this.docUpload.document_name);
      formData.append("description", this.docUpload.description);
      formData.append("property_request_uuid", this.docUpload.property_request_uuid);

      console.log(formData);

      propertySalesApi
        .savePropertyDocs(formData)
        .then(response => {
          console.log(response);
          this.successNotification("Success", "Files saved successfully");
          this.showUpload = false;
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    viewDocs(ppid) {
      this.property_requestId = ppid;
      this.showDocsTable = true;
    },
    closeModal(done) {
      this.$refs["dealDetails"].resetFields();
      done();
    },
    closedoc(done) {
      this.$refs["docUpload"].resetFields();
      done();
    }
  }
};
</script>
