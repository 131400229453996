import { mapGetters } from "vuex";
import is from "is_js";

export default {
  computed: {
    ...mapGetters({
      role: "getRole",
      permissions: 'getPermissions'
    })
  },
  methods: {
    userCan(perm) {
      if (is.inArray(perm, this.permissions) === true) {
        return true
      } else {
        return false
      }
    },
    closeSidebar() {
      var c = document.getElementById("doa-sidebar");
      c.classList.add("hidden-sm-and-down");
      c.classList.add("doa-sidebar");
      c.classList.remove("new-sidebar");
    },
    isNotNull(arg) {
      if (arg !== null) {
        return true
      } else {
        return false
      }
    },
    isNull(arg) {
      if (arg == null) {
        return true
      } else {
        return false
      }
    },
    notCollaborator() {
      if (this.role !== 'collaborator') {
        return true;
      } else {
        return false
      }
    },
    getGreetings() {
      let today = new Date()
      let curHr = today.getHours()

      if (curHr < 12) {
        return 'Good Morning'
      } else if (curHr < 18) {
        return 'Good Afternoon'
      } else {
        return 'Good Evening'
      }
    },
    getTodaysDateAndTime(date) {
      var objToday = new Date(date),
        weekday = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'),
        dayOfWeek = weekday[objToday.getDay()],
        domEnder = function () { var a = objToday; if (/1/.test(parseInt((a + "").charAt(0)))) return "th"; a = parseInt((a + "").charAt(1)); return 1 == a ? "st" : 2 == a ? "nd" : 3 == a ? "rd" : "th" }(),
        dayOfMonth = today + (objToday.getDate() < 10) ? '0' + objToday.getDate() + domEnder : objToday.getDate() + domEnder,
        months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'),
        curMonth = months[objToday.getMonth()],
        curYear = objToday.getFullYear(),
        curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours()),
        curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes(),
        curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";
      var today = '<b>' + dayOfWeek + " " + dayOfMonth + " " + curMonth + ", " + curYear + '<br> ' + curHour + ":" + curMinute + "." + curMeridiem + '</b>'
      return today;
    },
    getDate(date) {
      let objToday = new Date(date),
        weekday = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'),
        dayOfWeek = weekday[objToday.getDay()],
        domEnder = function () { var a = objToday; if (/1/.test(parseInt((a + "").charAt(0)))) return "th"; a = parseInt((a + "").charAt(1)); return 1 == a ? "st" : 2 == a ? "nd" : 3 == a ? "rd" : "th" }(),
        dayOfMonth = today + (objToday.getDate() < 10) ? '0' + objToday.getDate() + domEnder : objToday.getDate() + domEnder,
        months = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'),
        curMonth = months[objToday.getMonth()],
        curYear = objToday.getFullYear();

      console.log(dayOfWeek)

      let today = dayOfMonth + " " + curMonth + " " + curYear
      return today;
    },
    successMessage(message) {
      this.$message({
        showClose: true,
        message: message,
        type: 'success',
        duration: 2000
      });
    },
    successNotification(title, message) {
      this.$notify({
        title: title,
        message: message,
        type: 'success',
        duration: 6000
      });
    },
    warningNotification(title, message) {
      this.$notify({
        title: title,
        message: message,
        type: 'warning',
        duration: 6000
      });
    },
    errorMessage(message) {
      this.$message({
        showClose: true,
        message: message,
        type: 'error',
        duration: 3000
      });
    },
    infoMessage(message) {
      this.$message({
        showClose: true,
        message: message,
        duration: 2000
      });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push("/login")
    }
  }
}