<template>
  <div>
    <div v-loading="reviewLoading">
      <el-input class="mt-1" type="textarea" disabled v-model="review.body" />
      <div class="mt-1">
        <label><b>Collaborator Rating</b></label>
        <div class="mt-1">
          <el-rate
            disabled
            v-model="review.customer_service_rating"
            :texts="['oops', 'disappointed', 'normal', 'good', 'great']"
            show-text
          >
          </el-rate>
        </div>
        <br />
        <label><b>Property Ratings</b></label>
        <div class="mt-1">
          <el-rate
            disabled
            v-model="review.property_rating"
            :texts="['oops', 'disappointed', 'normal', 'good', 'great']"
            show-text
          >
          </el-rate>
        </div>

        <br />
        <span class="d-block"
          ><b>Recommendation </b>
          <el-tooltip effect="light" :content="review.recommend" placement="top">
            <el-switch
              disabled
              v-model="review.recommend"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="Yes"
              inactive-value="No"
            >
            </el-switch>
          </el-tooltip>
        </span>
        <br />
        <label><b>Overall Rating</b></label>
        <div class="mt-1 rate_window">
          <el-rate v-model="review.rating" disabled> </el-rate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reviewsApi from "@/api/reviews";

export default {
  props: {
    tripId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      noReviews: false,
      reviewLoading: true,
      review: {}
    };
  },
  created() {
    this.getReview();
  },
  methods: {
    getReview() {
      reviewsApi
        .getReview(this.tripId)
        .then(response => {
          this.review = response.data[0];
          this.reviewLoading = false;
        })
        .catch(error => {
          this.reviewLoading = false;
          this.errorMessage(error.message);
        });
    }
  }
};
</script>
