<template>
  <div>
    <el-row :gutter="20" class="mb-2">
      <el-col :xs="24" :sm="24" :md="24" :lg="24">
        <el-input placeholder="search trips" v-model="search" class="mt-4"> </el-input>
      </el-col>
    </el-row>

    <el-card>
      <el-table
        :data="tableData"
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column label="Collaborator">
          <template slot-scope="props">
            <span class="d-block"
              ><b> {{ props.row.collaborator }} </b></span
            >
            <span class="d-block">{{ props.row.collabContact }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Client">
          <template slot-scope="props">
            <span class="d-block"
              ><b>{{ props.row.clientName }}</b></span
            >
            <span class="d-block">{{ props.row.clientContact }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Status" prop="status">
          <template slot-scope="props">
            <el-tag
              size="small"
              :type="
                props.row.status == 'started'
                  ? 'success'
                  : props.row.status == 'approved'
                  ? 'primary'
                  : props.row.status == 'ended'
                  ? 'danger'
                  : 'warning'
              "
              >{{ props.row.status }}</el-tag
            >
            <span class="d-block mt-1">
              <span style="font-size:11px">Approved by</span> <br />
              <i class="el-icon-info"></i>
              <b> {{ props.row.is_approved_by }}</b>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Viewing Fee" prop="viewing_fee">
          <template slot-scope="props">
            <span class="d-block mt-1">
              <span style="font-size:11px">Viewing Date</span> <br />
              <i class="el-icon-info"></i>
              <b> {{ props.row.viewDate }}</b>
            </span>
            <el-tag
              size="small"
              :type="props.row.viewing_fee == 'unpaid' ? 'warning' : 'success'"
              >{{ props.row.viewing_fee }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="135">
          <template slot-scope="props">
            <el-tooltip class="item" effect="dark" content="Clear trip for viewing" placement="top">
              <el-button
                v-if="userCan('can_approve_property_trip') == true && props.row.status !== 'ended'"
                :disabled="props.row.clearTripDisabled"
                icon="el-icon-view"
                type="primary"
                plain
                size="mini"
                @click="confirmClearTrip(props.row.uuid)"
              />
            </el-tooltip>

            <el-tooltip class="item" effect="light" content="Start Trip" placement="top">
              <el-button
                :disabled="props.row.disableStartTrip"
                icon="el-icon-truck"
                type="success"
                size="mini"
                @click="collaboratorStartTrip(props.row.uuid)"
              />
            </el-tooltip>

            <el-tooltip class="item" effect="light" content="Pay Viewing Fee" placement="top">
              <el-button
                v-if="userCan('can_add_payment_on_viewing_for_property_trip')"
                :disabled="props.row.feeingDisabled"
                icon="el-icon-coin"
                type="warning"
                size="mini"
                @click="confirmViewingFee(props.row.uuid)"
              />
            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="See Review" placement="top">
              <el-button
                v-if="props.row.status == 'ended' && userCan('can_fetch_reviews_on_property_trip')"
                icon="el-icon-chat-line-square"
                type="info"
                size="mini"
                @click="showTripReview(props.row.uuid)"
              />
            </el-tooltip>
            <br /><br />
            <el-tooltip class="item" effect="light" content="End Trip" placement="top">
              <el-button
                class="d-block mt-1"
                v-if="props.row.status == 'started'"
                icon="el-icon-circle-close"
                type="danger"
                plain
                size="mini"
                @click="
                  confirmEndTrip(
                    props.row.uuid,
                    props.row.sample_property_viewings,
                    props.row.property_request_uuid
                  )
                "
                >End Trip</el-button
              >
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <div style="text-align: center;margin-top: 10px; padding-bottom:10px;">
      <el-pagination
        background
        :current-page.sync="currentPage"
        :page-sizes="[15, 50, 100]"
        :page-size="15"
        layout="prev, pager, next, sizes, total"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :visible.sync="showAddViewingFeeModal"
      width="25%"
      :before-close="closeModal"
      title="Add Trip Payment Fee"
    >
      <AddTripViewingFee
        v-if="showAddViewingFeeModal"
        v-on:closeModal="feePaid"
        :tripId.sync="selectedTripId"
      />
    </el-dialog>

    <!-- review -->
    <el-dialog :visible.sync="showTripReviewModal" width="25%" title="See Review">
      <TripReview v-if="showTripReviewModal" :tripId.sync="selectedTripId" />
    </el-dialog>

    <!-- all reviews -->
    <el-dialog :visible.sync="showReviewsModal" width="50%" title="Reviews">
      <AllReviews />
    </el-dialog>

    <el-dialog :visible.sync="showListing" width="30%" title="Client Selected Property">
      <SelectedPropertyByClient
        v-if="showListing"
        :listing="selectedPropertyView"
        :requestId="selectedPropertyRequest"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tripsApi from "@/api/trips";
import AddTripViewingFee from "@/components/AddTripViewingFee";
import TripReview from "@/components/TripReview";
import SelectedPropertyByClient from "@/components/SelectedPropertyByClient";
import AllReviews from "@/views/AllReviews";

export default {
  name: "Trips",
  components: {
    AddTripViewingFee,
    TripReview,
    AllReviews,
    SelectedPropertyByClient
  },
  data() {
    return {
      selectedPropertyView: null,
      selectedPropertyRequest: "",
      showListing: false,
      showReviewsModal: false,
      showTripReviewModal: false,
      selectedTripId: "",
      showAddViewingFeeModal: false,
      currentPage: 1,
      total: 0,
      search: "",
      tableData: [],
      tableLoading: false,
      query: {
        page: 1
      }
    };
  },
  computed: {
    ...mapGetters({
      role: "getRole",
      user: "getUser"
    }),
    isEnded: function(trip) {
      return trip == "ended";
    }
  },
  created() {
    this.getTrips();
  },
  methods: {
    getTrips() {
      this.tableLoading = true;
      tripsApi
        .getTrips(this.query)
        .then(response => {
          this.loadTable(response);
        })
        .catch(error => {
          this.tableLoading = false;
          this.errorMessage(error.message);
        });
    },
    loadTable(response) {
      let self = this;
      let trips = response.data.map(function(trip) {
        trip.clientName = trip.client
          ? `${trip.client.first_name} ${trip.client.last_name}`
          : "no client info";
        trip.clientContact = trip.client ? trip.client.contact : "no contact";
        trip.viewDate = self.getDate(trip.property_inquiry.view_time);
        trip.collaborator = `${trip.user.first_name} ${trip.user.last_name}`;
        trip.collabContact = trip.user.contact;
        trip.disableStartTrip = trip.status == "started" || trip.status == "ended" ? true : false;
        trip.clearTripDisabled =
          trip.is_approved_by !== null || trip.viewing_fee == "unpaid" ? true : false;
        trip.feeingDisabled =
          trip.viewing_fee == "unpaid" || trip.viewing_fee == null ? false : true;
        trip.is_approved_by = self.isNotNull(trip.is_approved_by)
          ? `${trip.is_approved_by.first_name} ${trip.is_approved_by.last_name}`
          : "none";
        return trip;
      });

      if (this.role == "collaborator") {
        this.tableData = trips.filter(trip => trip.user.uuid == this.user.uuid);
        this.total = this.tableData.length;
      } else {
        this.tableData = trips;
        this.total = response.pagination.total;
      }
      this.tableLoading = false;
    },
    confirmClearTrip(tripId) {
      this.$confirm("This will allow collaborator to start trip with client", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.clearForViewing(tripId);
        })
        .catch(() => {
          this.infoMessage("Cancelled");
        });
    },
    confirmEndTrip(tripId, viewingPropertyArray, propRequestId) {
      this.$confirm(
        "This will assume you are finished with a viewing and will end trip with client.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.endTrip(tripId, viewingPropertyArray, propRequestId);
        })
        .catch(() => {
          this.infoMessage("Cancelled");
        });
    },
    clearForViewing(id) {
      let tripInfo = {
        uuid: id,
        status: "approved"
      };
      tripsApi
        .updateMangerTripStatus(tripInfo)
        .then(response => {
          this.successNotification("Message", response.message);
          this.getTrips();
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    endTrip(id, viewingPropertyArray, propRequestId) {
      let tripInfo = {
        uuid: id,
        status: "ended"
      };
      this.selectedPropertyView = viewingPropertyArray;
      this.selectedPropertyRequest = propRequestId;
      tripsApi
        .endTrip(tripInfo)
        .then(response => {
          this.successNotification("Message", response.message);
          this.getTrips();
          this.showListing = true;
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    collaboratorStartTrip(tripId) {
      this.$confirm(
        "This will send the client an email to start the trip so it begins.",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          this.startTrip(tripId);
        })
        .catch(() => {
          this.infoMessage("Cancelled");
        });
    },
    startTrip(id) {
      let tripInfo = {
        uuid: id,
        user_longitude_points: "20.902939303",
        user_latitude_points: "-0.93994004",
        status: "started",
        client_or_rep_confirm_trip: ""
      };
      console.log(tripInfo);
      tripsApi
        .updateTripStatus(tripInfo)
        .then(response => {
          this.successNotification("Message", response.message);
          this.getTrips();
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    confirmViewingFee(id) {
      this.selectedTripId = id;
      this.showAddViewingFeeModal = true;
    },
    feePaid() {
      this.showAddViewingFeeModal = false;
      this.getTrips();
    },
    closeModal(done) {
      done();
    },
    showTripReview(tripId) {
      this.selectedTripId = tripId;
      this.showTripReviewModal = true;
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.query.page = page;
      this.getClients();
    }
  }
};
</script>
