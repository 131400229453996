import config from '../config'
import apiService from './api'

export default {
  name: 'propertySales',
  components: {
    apiService
  },

  getpropertySales(query) {
    return apiService.get(config.PROPERTY_SALES, query)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addPropertySales(salesData) {
    let url = config.PROPERTY_SALES
    return apiService.post(url, salesData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  updatePropertySales(sales) {
    let url = config.PROPERTY_SALES + '/' + sales.uuid
    return apiService.update(url, sales)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  savePropertyDocs(fileFormData) {
    let url = config.PROPERTY_DOCS + '/uploads'
    return apiService.post(url, fileFormData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getPropertyDocs(requestUuid) {
    let url = config.URL + 'show_property_request/' + requestUuid + '/documents'
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}