import apiService from './api'
import config from '../config'

export default {
  name: 'usersApi',
  components: {
    apiService
  },
  getUsers() {
    return apiService.get(config.USERS_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getUser(id) {
    let url = config.USERS_URL + '/' + id
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addUser(userData) {
    let url = config.REGISTER_URL
    return apiService.post(url, userData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  updateUser(userData) {
    let url = config.USERS_URL + '/' + userData.uuid
    return apiService.update(url, userData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getUserFiles(id) {
    let url = config.URL + 'user/' + id + '/collaborator_file'
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getCollaboratorFile(id) {
    let url = config.URL + 'show_client_collaborator/' + id + '/collaborator_file'
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}