<template>
  <div v-loading="loading">
    <el-form>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item>
            <h3>Areas assigned to broker</h3>
            <span class="d-block" style="font-size:12px">Press enter key to add</span>
            <el-tag
              :key="area.area"
              v-for="area in areas"
              closable
              :disable-transitions="false"
              @close="removeAreaAssigned(area)"
            >
              {{ area.area }}
            </el-tag>
            <el-select
              class="input-new-area"
              @keyup.enter.native="addAreaConfirm"
              v-model="areaDetails.area"
              v-if="inputAreaVisible"
              placeholder="Select region"
            >
              <el-option v-for="region in regions" :key="region" :label="region" :value="region">
              </el-option>
            </el-select>
            <el-button v-else class="button-new-area" size="small" @click="showAddAreaInput"
              >+ New Area</el-button
            >
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item>
            <h3>Colaborators assigned to broker</h3>
            <el-tag
              :key="user.collaborator_name"
              v-for="user in assignedCollaborators"
              closable
              :disable-transitions="false"
              @close="removeCollaboratorAssigned(user)"
            >
              {{ user.collaborator_name }}
            </el-tag>
            <el-select
              class="input-new-area"
              @keyup.enter.native="addCollaboratorConfirm"
              v-model="assignCollaboratorDetails.user_uuid"
              v-if="inputCollaboratorVisible"
              placeholder="Select collaborator"
            >
              <el-option
                v-for="user in collaborators"
                :key="user.uuid"
                :label="user.name"
                :value="user.uuid"
              >
              </el-option>
            </el-select>
            <el-button v-else class="button-new-area" size="small" @click="showCollaboratorInput"
              >+ New Collaborator</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-button
        class="full-width"
        type="primary"
        icon="el-icon-house"
        :loading="btnLoading"
        @click="assignArea"
        >Assign area to broker</el-button
      > -->
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import brokersApi from "@/api/brokers";
import usersApi from "@/api/users";

export default {
  props: {
    userId: String
  },
  data() {
    return {
      inputAreaVisible: false,
      inputCollaboratorVisible: false,
      loading: false,
      areas: [],
      collaborators: [],
      assignedCollaborators: [],
      areaDetails: {
        supervisor_uuid: "",
        area: ""
      },
      assignCollaboratorDetails: {
        supervisor_uuid: "",
        user_uuid: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      regions: "getRegions"
    })
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.areaDetails.supervisor_uuid = this.userId;
      this.assignCollaboratorDetails.supervisor_uuid = this.userId;
      this.getAreasAssigned();
      this.getCollaboratorsAssigned();
      this.getCollaborators();
    },
    getAreasAssigned() {
      let brokerId = {
        supervisor_uuid: this.userId
      };
      brokersApi
        .getAssignedAreas(brokerId)
        .then(response => {
          this.areas = response.data;
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    getCollaboratorsAssigned() {
      let brokerId = {
        supervisor_uuid: this.userId
      };
      brokersApi
        .getBrokerCollaborators(brokerId)
        .then(response => {
          this.assignedCollaborators = response.data;
        })
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    getCollaborators() {
      usersApi
        .getUsers()
        .then(response => {
          let users = response.data.map(function(user) {
            user.role = user.role.length === 0 ? "no role" : user.role[0].role;
            user.name = user.first_name + " " + user.last_name;
            return user;
          });

          this.collaborators = users.filter(user => user.role == "collaborator");
        })
        .catch(error => this.errorMessage(error.message));
    },
    assignArea() {
      this.loading = true;
      if (this.userCan("can_add_supervising_areas_to_broker") == true) {
        brokersApi
          .addAreaToBroker(this.areaDetails)
          .then(() => {
            this.loading = false;
            this.successNotification("Success", "Area assigned successfully");
          })
          .catch(error => {
            this.loading = false;
            this.errorMessage(error.message);
          });
      } else {
        this.infoMessage("You dont have permmission for this action.");
      }
    },
    assignCollaborator() {
      this.loading = true;
      if (this.userCan("can_add_collaborator_to_supervising_broker") == true) {
        brokersApi
          .assignCollaborator(this.assignCollaboratorDetails)
          .then(() => {
            this.loading = false;
            this.successNotification("Success", "Collaborator added successfully");
            this.$emit("closeModal");
          })
          .catch(error => this.errorMessage(error.message));
      } else {
        this.infoMessage("You dont have permmission for this action.");
      }
    },
    showAddAreaInput() {
      this.inputAreaVisible = true;
    },
    showCollaboratorInput() {
      this.inputCollaboratorVisible = true;
    },
    addAreaConfirm() {
      let inputValue = this.areaDetails.area;
      if (inputValue) {
        this.areas.push({ area: inputValue });
        this.assignArea();
      }
      this.inputAreaVisible = false;
    },
    addCollaboratorConfirm() {
      let inputValue = this.assignCollaboratorDetails.user_uuid;
      if (inputValue) {
        this.assignCollaborator();
      }
      this.inputCollaboratorVisible = false;
    },
    removeAreaAssigned(area) {
      this.areas.splice(this.areas.indexOf(area), 1);
      console.log(area);
    },
    removeCollaboratorAssigned(collaborator) {
      this.assignedCollaborators.splice(this.assignedCollaborators.indexOf(collaborator), 1);
    }
  }
};
</script>
