import Vue from 'vue';
import VueRouter from 'vue-router';
import is from "is_js";
import store from '../store/';

import Main from '@/layouts/Main.vue';
import Dashboard from '@/views/Dashboard.vue';
import Enquiries from '@/views/Enquiries.vue';
import Requests from '@/views/PropertyRequests.vue';
import Trips from '@/views/Trips.vue';
import PropertySales from '@/views/PropertySales';
import Payment from '@/views/Payments.vue';
import Messages from '@/views/Messages.vue';
import Users from '@/views/Users'
import Clients from '@/views/Clients'
import Roles from '@/views/Roles'
import Account from '@/views/Account'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
    component: Main,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/enquiries',
        name: 'Enquiries',
        component: Enquiries,
      },
      {
        path: '/property-requests',
        name: 'Requests',
        component: Requests,
      },
      {
        path: '/trips',
        name: 'Trips',
        component: Trips,
      },
      {
        path: '/property-sales',
        name: 'Property sales',
        component: PropertySales,
      },
      {
        path: '/payments',
        name: 'Payments',
        component: Payment,
      },
      {
        path: '/messages',
        name: 'Communications',
        component: Messages,
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
      },
      {
        path: '/clients',
        name: 'Clients',
        component: Clients,
      },
      {
        path: '/roles',
        name: 'Roles',
        component: Roles,
      },
      {
        path: '/account',
        name: 'Acount',
        component: Account,
      },
    ],
  },
  {
    path: '/confirm_trip',
    name: 'Confirm Trip',
    component: () => import('../views/ConfirmTrip.vue'),
  },
  {
    path: '/tripreview',
    name: 'Review Trip',
    component: () => import('../views/ClientReview.vue'),
  },
  // {
  //   path: '/tripmap',
  //   name: 'Maps',
  //   component: TripMap,
  // },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let openViews = ["Login", "Confirm Trip", "Review Trip"];
  let canAccess =
    is.inArray(to.name, openViews) || store.getters.loggedIn;
  return canAccess ? next() : next("/login");
});

export default router;
