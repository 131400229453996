<template>
  <div>
    <el-form :model="messageDetails" :rules="rules" ref="messageDetails">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item>
            <el-select
              v-model="channelId"
              class="full-width"
              placeholder="Select channel"
              @change="setChannel"
            >
              <el-option
                v-for="c in channels"
                :key="c.uuid"
                :label="c.channel_name"
                :value="c.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-select
              v-model="messageDetails.recipients"
              class="full-width"
              placeholder="Select recipients"
              multiple
              collapse-tags
              filterable
            >
              <el-option
                v-for="user in clients"
                :key="user.uuid"
                :label="user.name"
                :value="user.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item>
            <el-input v-model="messageDetails.title" placeholder="title"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item prop="message">
            <el-input
              type="textarea"
              placeholder="Enter message"
              v-model="messageDetails.message"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        class="full-width"
        type="primary"
        icon="el-icon-s-promotion"
        :loading="btnLoading"
        @click="messageUser"
        >Send Message</el-button
      >
    </el-form>
  </div>
</template>

<script>
import messagesApi from "@/api/messages";
import clientsApi from "@/api/clients";

export default {
  data() {
    return {
      btnLoading: false,
      clients: [],
      channels: [],
      channelId: "",
      messageDetails: {
        title: "Notification",
        message: "",
        recipients: []
      },
      rules: {
        message: [
          {
            required: true,
            message: "message is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  created() {
    this.getClients();
    this.getChannels();
  },
  methods: {
    getClients() {
      clientsApi
        .getClients()
        .then(response => {
          let clients = response.data.map(function(client) {
            client.name = client.first_name + " " + client.last_name;
            return client;
          });

          this.clients = clients;
        })
        .catch(error => this.errorMessage(error.message));
    },
    getChannels() {
      messagesApi
        .getMessageChannels()
        .then(response => {
          this.channels = response.data;
        })
        .catch(error => this.errorMessage(error.message));
    },
    setChannel(v) {
      messagesApi
        .setChannelDefault(v)
        .then(() => {})
        .catch(error => {
          this.errorMessage(error.message);
        });
    },
    messageUser() {
      this.btnLoading = true;
      this.$refs["messageDetails"].validate(valid => {
        if (valid) {
          messagesApi
            .createMessage(this.messageDetails)
            .then(() => {
              this.btnLoading = false;
              this.$emit("messaged");
            })
            .catch(error => {
              this.btnLoading = false;
              this.errorMessage(error.message);
            });
        } else {
          this.btnLoading = false;
          return false;
        }
      });
    }
  }
};
</script>
