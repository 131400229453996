import apiService from './api'
import config from '../config'

export default {
  name: 'inquiriesApi',
  components: {
    apiService
  },

  getInquiries() {
    return apiService.get(config.INQUIRIES_URL)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  addInquiry(inquiryData) {
    let url = config.INQUIRIES_URL
    return apiService.post(url, inquiryData)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  editInquiry(inquiries) {
    let url = config.INQUIRIES_URL + "/" + inquiries.uuid
    return apiService.update(url, inquiries)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  confirmAnEnquiry(enquiryId) {
    let url = config.INQUIRIES_URL + "/confirm"
    return apiService.post(url, enquiryId)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  deleteEnquiry(enquiryId) {
    let url = config.INQUIRIES_URL + '/' + enquiryId
    return apiService.delete(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getEnquiryByStatus(query) {
    let url = config.URL + 'inquiries'
    return apiService.get(url, query)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getEnquirySourceCount(enquirySort) {
    let url = config.ENQUIRY_SOURCE_COUNT
    return apiService.get(url, enquirySort)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}