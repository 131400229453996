import apiService from './api'
import config from '../config'

export default {
  name: 'paymentsApi',
  components: {
    apiService
  },
  addTripViewingFee(feeInfo) {
    let url = config.PAYMENTS_URL;
    return apiService.post(url, feeInfo)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getTripViewingFees() {
    let url = config.PAYMENTS_URL;
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  }
}