<template>
  <div>
    <span class="d-block"
      >Name: <b>{{ client.first_name + " " + client.last_name }}</b></span
    >

    <span class="d-block"
      >Email: <b>{{ client.email }}</b></span
    >

    <span class="d-block"
      >Contact: <b>{{ client.contact }}</b></span
    >
    <span class="d-block"
      >Gender: <b>{{ client.gender }}</b></span
    >
    <hr />
    <span class="d-block"
      >Age Range: <b>{{ client.age_range }}</b></span
    >
    <span class="d-block"
      >Proffession: <b>{{ client.profession }}</b></span
    >
    <span class="d-block"
      >Marital Status: <b>{{ client.marital_status }}</b></span
    >
  </div>
</template>

<script>
export default {
  props: {
    client: Object
  },
  data() {
    return {};
  }
};
</script>
