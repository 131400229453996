<template>
  <div style="height: 100vh; background:#FFFDF9;">
    <el-aside class="doa-sidebar hidden-sm-and-down" id="doa-sidebar">
      <el-menu
        :default-openeds="['1', '8']"
        default-active="1"
        router
        text-color="rgb(156, 155, 155)"
        active-text-color="grey"
        style="height: 100vh"
      >
        <div class="sidebar_logo">
          <img src="../assets/images/dwellys-logo.png" alt="logo" height="35px" />
          <i
            class="el-icon-close hidden-md-and-up"
            @click="closeSidebar"
            style="font-weight:bold; font-size:20px;"
          ></i>
        </div>
        <el-menu-item index="1" :route="{ path: '/dashboard' }">
          <i class="el-icon-menu"></i> Dashboard
        </el-menu-item>

        <el-menu-item
          index="2"
          :route="{ path: '/enquiries' }"
          v-if="userCan('can_fetch_inquiries')"
        >
          <i class="el-icon-phone-outline"></i>
          Enquiries
        </el-menu-item>

        <el-menu-item index="3" :route="{ path: '/property-requests' }">
          <i class="el-icon-thumb"></i>
          Property Requests
        </el-menu-item>

        <el-menu-item index="4" :route="{ path: '/trips' }" v-if="role !== 'front_desk'">
          <i class="el-icon-truck"></i>
          Trips
        </el-menu-item>

        <el-menu-item
          index="9"
          :route="{ path: '/property-sales' }"
          v-if="role !== 'front_desk' && role !== 'collaborator'"
        >
          <i class="el-icon-office-building"></i>
          Property Sales
        </el-menu-item>

        <el-menu-item
          index="5"
          :route="{ path: '/payments' }"
          v-if="userCan('can_fetch_payments_on_viewing_for_property_trips')"
        >
          <i class="el-icon-coin"></i>
          Payments
        </el-menu-item>

        <el-menu-item
          index="6"
          :route="{ path: '/messages' }"
          v-if="userCan('can_fetch_sent_messages')"
        >
          <i class="el-icon-chat-dot-square"></i>
          Messaging
        </el-menu-item>

        <el-menu-item index="7" :route="{ path: '/clients' }" v-if="userCan('can_fetch_clients')">
          <i class="el-icon-user"></i>
          Clients
        </el-menu-item>

        <el-submenu index="8" v-show="role !== 'collaborator'">
          <template slot="title"><i class="el-icon-s-tools"></i>System</template>
          <el-menu-item-group>
            <el-menu-item
              index="8-1"
              :route="{ path: '/users' }"
              v-if="userCan('can_fetch_all_users_with_or_without_roles')"
            >
              <i class="el-icon-user-solid"></i>
              Users
            </el-menu-item>
            <el-menu-item index="8-2" :route="{ path: '/roles' }" v-if="role !== 'front_desk'">
              <i class="el-icon-s-check"></i>
              Roles
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-menu-item index="9" @click="logout">
          <span>
            <i class="el-icon-turn-off"></i>
            Logout
          </span>
        </el-menu-item>

        <!-- <el-menu-item index="6" :route="{ path: '/users' }">
        <i class="el-icon-user-solid"></i>
        Users
      </el-menu-item> -->

        <!-- <div class="sidebar_accounts">
          <el-menu-item index="7">
            <i class="el-icon-s-tools"></i>
            Account Settings
          </el-menu-item>
          <el-menu-item index="8" @click="logout">
            <span>
              <i class="el-icon-turn-off"></i>
              Logout
            </span>
          </el-menu-item>
        </div> -->
      </el-menu>
    </el-aside>

    <div class="main-container" id="main-container">
      <el-header>
        <Header />
      </el-header>

      <el-main>
        <router-view />
      </el-main>

      <!-- <footer class="sidebar_accounts hidden-sm-and-down">
        <div class="">
          <p>Dwellys International Limited © 2020. All right reserved.</p>
          <p>v-1.0.5</p>
        </div>
      </footer> -->
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import { mapGetters } from "vuex";

export default {
  name: "Main",
  components: {
    Header
  },
  computed: {
    ...mapGetters({
      role: "getRole"
    })
  }
};
</script>
