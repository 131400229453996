<template>
  <div>
    <div class="mb-2 mt-1">
      <el-input placeholder="search" v-model="search" class="mt-4 full-width">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>

    <el-card>
      <el-table
        :data="
          tableData.filter(
            data => !search || data.clientName.toLowerCase().includes(search.toLowerCase())
          )
        "
        v-loading="tableLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <p><b>Request created by: </b> {{ props.row.createdBy }}</p>
            <p><b>Description</b> {{ props.row.property_inquiry.description }}</p>
            <p>
              <b>Source: </b> <el-tag size="small">{{ props.row.property_inquiry.source }}</el-tag>
            </p>
            <div v-if="role !== 'collaborator'">
              <p><b>Garages: </b> {{ props.row.property_inquiry.garages }}</p>
              <p><b>Bedrooms: </b> {{ props.row.property_inquiry.bedrooms }}</p>
              <p><b>Bathrooms: </b> {{ props.row.property_inquiry.bathrooms }}</p>
            </div>

            <p><b>Move-in date: </b> {{ props.row.property_inquiry.move_in }}</p>
            <p><b>Inquiry date: </b> {{ props.row.created_at }}</p>
            <p>
              <b>Proeprty URL inquired: </b>
              <span
                v-for="listing in props.row.sample_property_viewing"
                :key="listing.id"
                style="margin-right:10px;"
              >
                <a :href="listing.listing" target="_blank">See property</a>
              </span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Client" width="220px">
          <template slot-scope="props">
            <div class="clickable" @click="showClient(props.row.client)">
              <el-button type="text" size="mini">
                <i class="el-icon-user"></i>
                {{ props.row.clientName }}
              </el-button>
              <span class="d-block">
                {{ props.row.clientEmail }}
              </span>
              <span>
                <i class="el-icon-info"></i> <b>{{ props.row.clientRep }}</b>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Property Type">
          <template slot-scope="props">
            <span class="d-block">
              {{ props.row.property_inquiry.type }}
            </span>

            <span class="d-block mt-1">
              <el-tag size="small" type="success"
                >For {{ props.row.property_inquiry.category }}</el-tag
              >
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Status">
          <template slot-scope="props">
            <el-tag size="small" type="info">{{ props.row.status }}</el-tag>
            <span class="d-block mt-1">
              <span style="font-size:11px">Assigned to</span> <br />
              <i class="el-icon-info"></i>
              <b> {{ props.row.collaborator }}</b>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Budget" v-if="role !== 'collaborator'" width="150px">
          <template slot-scope="props">
            <span class="d-block">
              <el-tag size="mini" type="primary"
                >Min: GH₵ {{ props.row.property_inquiry.price_from }}</el-tag
              >
            </span>
            <span class="d-block">
              <el-tag size="mini" type="primary"
                >Max: GH₵ {{ props.row.property_inquiry.price_to }}</el-tag
              >
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Viewing Date" width="150px">
          <template slot-scope="props">
            <span class="d-block">
              <b>{{ getDate(props.row.property_inquiry.view_time) }}</b>
            </span>
            <span class="d-block"><b>Location:</b> {{ props.row.property_inquiry.location }}</span>
            <span class="d-block"><b>Region:</b> {{ props.row.property_inquiry.region }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="135">
          <template slot-scope="props">
            <div v-if="role !== 'collaborator'">
              <el-tooltip
                class="item"
                effect="light"
                content="Assign property to collaborator"
                placement="top"
              >
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-finished"
                  @click="assignPropertyRequest(props.row.uuid)"
                />
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="light"
                content="Add a rep for client"
                placement="top"
              >
                <el-button
                  :disabled="props.row.repDisabled"
                  type="info"
                  size="mini"
                  icon="el-icon-user"
                  @click="showAddRepForm(props.row.uuid)"
                  v-if="userCan('can_add_rep_to_property_request')"
                />
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="light"
                content="Clear for viewing"
                placement="top"
                v-if="type !== 'confirmed'"
              >
                <el-button
                  :disabled="props.row.tripDisabled"
                  type="success"
                  size="mini"
                  icon="el-icon-check"
                  v-if="userCan('can_add_property_trips')"
                  @click="confirmProperty(props.row.uuid)"
                />
              </el-tooltip>
            </div>

            <span v-else>No actions</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <div style="text-align: center;margin-top: 10px; padding-bottom:10px;">
      <el-pagination
        background
        :current-page.sync="currentPage"
        :page-sizes="[20, 50, 100]"
        :page-size="20"
        layout="prev, pager, next, sizes, total"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- assign property requests -->
    <el-dialog
      :visible.sync="assignRequestsModal"
      width="30%"
      title="Assign Property"
      custom-class="mobile-modal"
    >
      <AssignPropertyRequest
        v-if="assignRequestsModal"
        :requestId.sync="selectedPropertyReq"
        v-on:closeModal="propertyAssigned"
      />
    </el-dialog>

    <!-- show client dialog -->
    <el-dialog
      :visible.sync="showAddRepModal"
      width="25%"
      :before-close="closeModal"
      title="Add a rep"
      custom-class="mobile-modal"
    >
      <AddRepToPropertyRequest
        v-if="showAddRepModal"
        v-on:closeModal="repAssigned"
        :requestId.sync="selectedPropertyReq"
      />
    </el-dialog>

    <!-- show client dialog -->
    <el-dialog
      :visible.sync="showClientModal"
      width="25%"
      :before-close="closeModal"
      title="Client Details"
      custom-class="mobile-modal"
    >
      <ShowClientDetails v-if="showClientModal" :client.sync="selectedClient" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import propertiesApi from "@/api/properties";
import tripsApi from "@/api/trips";
import AssignPropertyRequest from "@/components/AssignPropertyRequest";
import ShowClientDetails from "@/components/ShowClientDetails";
import AddRepToPropertyRequest from "@/components/AddRepToPropertyRequest";

export default {
  props: {
    type: String
  },
  components: {
    AssignPropertyRequest,
    ShowClientDetails,
    AddRepToPropertyRequest
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      search: "",
      tableData: [],
      showAddRepModal: false,
      tableLoading: false,
      showClientModal: false,
      selectedClient: null,
      selectedPropertyReq: null,
      assignRequestsModal: false,
      query: {
        page: 1,
        per_page: 20
      }
    };
  },
  computed: {
    ...mapGetters({
      role: "getRole",
      user: "getUser"
    })
  },
  created() {
    this.getPropertyRequests();
  },
  methods: {
    getPropertyRequests() {
      this.tableLoading = true;
      if (this.role == "collaborator") {
        propertiesApi
          .getCollaboratorPropertyRequest(this.user.uuid)
          .then(response => {
            this.loadTable(response.data);
          })
          .catch(error => this.errorMessage(error.message));
      } else {
        propertiesApi
          .getpropertyRequests(this.query)
          .then(response => {
            this.loadTable(response.data);
          })
          .catch(error => this.errorMessage(error.message));
      }
    },
    loadTable(data) {
      let self = this;
      let propertyRequest = data.map(function(property) {
        property.clientName = `${property.client.first_name} ${property.client.last_name}`;
        property.clientEmail = property.client.email;
        property.createdBy = `${property.created_by.first_name} ${property.created_by.last_name}`;
        property.url =
          property.sample_property_viewing.length > 0
            ? property.sample_property_viewing[0].listing
            : "no property selected";
        property.tripDisabled = self.isNull(property.user_id);
        property.repDisabled = self.isNotNull(property.rep_id);
        property.clientRep = self.isNotNull(property.rep_id) ? property.rep.name : "Has no rep";
        property.collaborator = self.isNull(property.user_id)
          ? "not approved"
          : `${property.user_collaborator.first_name} ${property.user_collaborator.last_name}`;
        return property;
      });

      if (this.type == "request") {
        this.tableData = propertyRequest.filter(req => req.status == "request");
        this.total = this.tableData.length;
        this.$emit("loaded", { type: this.type, total: this.tableData.length });
      } else if (this.type == "confirmed") {
        this.tableData = propertyRequest.filter(req => req.status == "confirmed");
        this.total = this.tableData.length;
        this.$emit("loaded", { type: this.type, total: this.tableData.length });
      }
      this.tableLoading = false;
    },
    assignPropertyRequest(id) {
      this.selectedPropertyReq = id;
      this.assignRequestsModal = true;
    },
    confirmProperty(propRequestId) {
      this.$confirm("This will create a trip to be initialized with client", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning"
      })
        .then(() => {
          this.createTrip(propRequestId);
        })
        .catch(() => {
          this.infoMessage("Cancelled");
        });
    },
    createTrip(id) {
      let trip = {
        property_request_uuid: id,
        link_sent_to: "client"
      };
      tripsApi
        .createTrip(trip)
        .then(response => {
          if (response.message == "error") {
            this.infoMessage(response.data);
          } else {
            this.successNotification("Success", "Trip is set successfully");
            this.$router.go();
          }
        })
        .catch(error => this.errorMessage(error.message));
    },
    showClient(client) {
      this.selectedClient = client;
      this.showClientModal = true;
    },
    showAddRepForm(requestId) {
      this.selectedPropertyReq = requestId;
      this.showAddRepModal = true;
    },
    propertyAssigned() {
      this.assignRequestsModal = false;
      this.$router.go();
    },
    repAssigned() {
      this.showAddRepModal = false;
      this.getPropertyRequests();
    },
    closeModal(done) {
      done();
    }
  }
};
</script>
