import apiService from './api'
import config from '../config'

export default {
  name: 'dashboardApi',
  components: {
    apiService
  },

  getActivityLog() {
    let url = config.URL + 'activity_logs'
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getDashboardTotal() {
    let url = config.URL + 'dashboard'
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
  getCollaboratorDashboard(collaboratorId) {
    let url = config.URL + 'user_dashboard/' + collaboratorId
    return apiService.get(url)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error))
  },
}