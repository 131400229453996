import Pusher from 'pusher-js'
import eventbus from "@/eventbus";

let pusher = new Pusher('a63f60b59b7f215d8b07', {
  cluster: 'mt1'
});

let channel = pusher.subscribe('dwellys');

channel.bind('PropertyInquiryEvent', function (data) {
  eventbus.$emit('new_property_enquiry', data);
});

channel.bind('PropertyRequestAssignToCollaboratorEvent', function (data) {
  eventbus.$emit('assign_property_request', data);
});

channel.bind('PropertyRequestTripsEvent', function (data) {
  eventbus.$emit('trip_ended', data);
});